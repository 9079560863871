import React, { useEffect } from 'react';
import '../App.css';
import { AppBar, Box, createTheme, CssBaseline, Grid, Link, ThemeProvider, Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import Header from '../components/Header';
import ContactScheme from '../components/ContactScheme';
import StickyFooter from '../components/Footer';

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function Contact() {
    useEffect(() => {
        document.title = 'Kontakt - Norwegian Ghost Hunters';
    });
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppBar position="sticky" color="transparent">
                    <Header />
                </AppBar>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Forsiden
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                                href="#"
                            >
                                Kontakt oss
                            </Link>
                        </Breadcrumbs>
                    </Grid></Grid>
                <Grid item xs={12} spacing={2}>
                    <Typography
                        component="h1"
                        variant="h5"
                        align="center"
                        color="text.secondary"
                        gutterBottom
                    >
                        Kontakt oss
                    </Typography>
                    <ContactScheme />
                </Grid>
                <StickyFooter />
            </ThemeProvider>
        </Box>
    );
}
export default Contact;