import axios from "axios";

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

export default axios.create({
  withCredentials: false,
  baseURL: "https://api2.paraoffice.com:8080/api/",
  headers: {
    "Content-type": "application/json"
  }
});