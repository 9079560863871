import * as React from 'react';
import Typography from '@mui/material/Typography';
import { AppBar, Box, Breadcrumbs, createTheme, CssBaseline, Grid, Link, Stack, ThemeProvider } from '@mui/material';
import Header from '../components/Header';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import HomeIcon from '@mui/icons-material/Home';
import { useEffect } from 'react';

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function FAQ() {
    useEffect(() => {
        document.title = 'FAQ - Norwegian Ghost Hunters';
    });
    const [expanded, setExpanded] = React.useState<string | false>('');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppBar position="sticky" color="transparent">
                    <Header />
                </AppBar>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Forsiden
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                                href="#"
                            >
                                Ofte stilte spørsmål
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                            color="text.secondary"
                            gutterBottom
                        >
                            Ofte stilte spørsmål
                        </Typography>
                        <Stack direction="row" alignItems="center" gap={1} mt={5} mb={2} className="HeaderTitle">
                            <Typography sx={{ flex: 1 }} className='TitleText'>Spørsmål og svar</Typography>
                        </Stack>

                        <div>
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                    <Typography>Finnes det spøkelser på ekte?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Hvis vi kunne besvart dette spørsmålet så hadde vi kanskje ikke drevet med det vi gjør. Det er nettopp dette vi undersøker og prøver å forstå. Begrepet spøkelser er også et begrep som blir brukt i et litt vidt spekter av hendelser man oppfatter som uforklarlige. Mange sier for eksempel "her spøker det" og sikter egentlig til at det bare skjer ting man ikke kan forklare, men det er en lang vei å gå fra å si det, til å mene at det er døde mennesker som går igjen. Når det er nevnt så har vi etter mange år i feltet hatt mange rare opplevelser som vi ikke helt klarer å forklare. Men om det er "spøkelser" har vi ikke et endelig svar på.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                    <Typography>Koster det noe å få en undersøkelse fra dere?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Nei, vi utfører alle våre oppdrag helt gratis. Men på grunn av dette så betyr det også at vi har begrenset med kapasitet og økonomi til å reise rundt. Vi kan derfor ikke si ja til alle forespørsler vi mottar. Når vi vurderer et oppdrag så ser vi på for eksempel hyppighet av hendelser, om det er flere personer enn en person som har opplevelser, hva slags type hendelser, og hvor lang tid det er siden sist hendelse.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                    <Typography>Kan jeg bli med på en undersøkelse?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Vi kan ikke ta med personer utenom teamet vårt på private undersøkelser, men det hender at vi har arrangement og større oppdrag hvor vi inviterer besøkende. Følg med på vår Facebook side for å få oppdateringer når slikt planlegges, de første som tar kontakt er de vi prioriterer å ta med.                            </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                                    <Typography>Hva er det skumleste dere har opplevd?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Her er det veldig mange som har individuelle opplevelser som er av helt forskjellig karakter. Men tror de fleste i teamet (med unntak av de som lider av araknofobi) er enige om at det mest skremmende er når man føler man blir berørt av noe som man ikke kan se.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                                    <Typography>Hva er aldersgrensen for å være med?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        For å bli en del av teamet vårt må du være fyllt 18 år. Dette er fordi du må være myndig og ansvarlig for deg selv og egne handlinger. Vi gjennomfører oppdrag på steder som kan innebære risiko for egen helse, og da må det være på eget ansvar at man deltar. Du må også være myndig for å kunne undertegne kontrakter som for eksempel taushetserklæring.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                                    <Typography>Hvordan fungerer en undersøkelse?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Vi har litt forskjellige fremgangsmetoder avhengig av type oppdrag. Når det gjelder private oppdrag så er det ofte vi tilpasser oss en del i forhold til oppdragsgiver. Men vi trenger tilgang på stedet over en kveld og helst litt utover natten. Det beste for oss er at stedet er tomt for andre personer, men dersom man har et stort ønske om å være tilstede så kan vi tilpasse oss det, bare vi har kontrollerte omgivelser så vi ikke mistolker for eksempel lyder på opptak i ettertid. I ettertid av gjennomføringen av en undersøkelse så går vi igjennom alle opptak vi har gjort, og returnerer tilbake til stedet for å vise frem funn på opptak og forklare hva vi opplevde. På andre type store oppdrag kan det være videoproduksjon som har et større fokus.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                                    <Typography>Hvorfor undersøker dere når det er mørkt?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Det er faktisk flere grunner til dette, og det har ingen ting med at det skal være mer skummelt å gjøre. Veldig mange historier om spesielle opplevelser har foregått på kveld eller natt, og da blir det også naturlig at vi gjennomfører undersøkelser når det skal være størst sannsynlighet for at noe skjer. I tillegg så er det også enklere for oss å ha kontroll på skygger og rare lysfenomener, når vi kun har en lyskilde (infrarødt lys fra kamera). Det er også naturlig roligere og mindre støy, spesielt i byområder på tidspunkt hvor det er mørkt.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                                <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                                    <Typography>Hva slags utstyr bruker dere?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Det vikttigste vi bruker er alltid kamera og høykvalitets lyd-opptakere. Men vi bruker å eksperimentere med veldig mye forskjellig type teknisk utstyr som kan dokumentere opplevelser man har på en visuell måte. Det vi i utgangspunktet er ute etter å dokumentere er gjenskapning av opplevelser som har skjedd tidligere. Utstyret vi bruker er ofte måleinstrumenter som kan hjelpe oss med å dokumentere for eksempel energi i forskjellig form (statisk, elektromagnetisk, etc.). Du kan lese mer om utstyr <Link href='/utstyr'>her</Link>.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                                <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
                                    <Typography>Kan man være tilstede når dere undersøker?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        På private oppdrag kan boligeier være tilstede hvis ønskelig. Men desto færre folk tilstede, desto bedre og mer kontrollerte undersøkelser får vi gjort. Det er en del krav til tistedeværelse for at vi skal kunne gjennomføre en kontrollert undersøkelse som for eksempel at mobiltelefoner må skrus av.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                                <AccordionSummary aria-controls="panel10d-content" id="panel10d-header">
                                    <Typography>Tilbyr dere husrens?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Nei. Vår oppgave og formål er å undersøke og dokumentere. Dersom vi gjennomfører en undersøkelse og man ønsker en eller annen form for rensing i ettertid så har vi kontakter vi kan henvise til, men vi anbefaler det ikke og det er uten at vi garanterer noen form for effekt ut av det.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                                <AccordionSummary aria-controls="panel11d-content" id="panel11d-header">
                                    <Typography>Gjennomfører dere oppdrag anonymt uten publisering?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Ja. Vi kan gjennomføre oppdrag helt uten publisitet. En god del av oppdragene vi har gjennomført vil du ikke finne noe informasjon om.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                                <AccordionSummary aria-controls="panel12d-content" id="panel12d-header">
                                    <Typography>Hvor lenge har dere drevet på?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Norwegian Ghost Hunters ble startet opp som en gruppe i 2009. I 2021 ble det etablert som en større organisasjon som samler flere team som opererer på samme måte i Norge. Det opprinnelige teamet går nå under navnet Norwegian Ghost Hunters Oslo, som er et offisielt team under organisasjonen.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                                <AccordionSummary aria-controls="panel13d-content" id="panel13d-header">
                                    <Typography>Hvor mange steder har dere undersøkt?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Norwegian Ghost Hunters organisasjonen består av flere team, så du kan se statistikk for hvert team <Link href='/team'>her</Link>.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
                                <AccordionSummary aria-controls="panel14d-content" id="panel14d-header">
                                    <Typography>Har dere et hovedkontor?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Nei vi har ingen fysisk adresse for organisasjonen. Innad i organisasjonen har vi daglig kontakt via digitale verktøy, som for eksempel <Link href='/discord'>Discord</Link>, hvor det også er mulig for publikum å delta. Vi har også utviklet egne systemer som gjør at vi kan sitte hjemme når vi går igjennom opptak og gjør etterarbeidet etter undersøkelsene.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
                                <AccordionSummary aria-controls="panel15d-content" id="panel15d-header">
                                    <Typography>Hvor lenge må man vente til dere kommer?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Dette varierer veldig avhengig av pågang. Vi har også hatt et etterslep på private oppdrag grunnet situasjonen med covid-19. Hvis det er ekstreme tilfeller som haster så kan vi ha muligheten til å gjøre noen prioriteringer. Dette er vurderinger som gjøres ved hver enkelt henvendelse.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel16'} onChange={handleChange('panel16')}>
                                <AccordionSummary aria-controls="panel16d-content" id="panel16d-header">
                                    <Typography>Hvor mange er det som deltar på undersøkelsene?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Dette tilpasser vi stedene vi undersøker. Små bolighus trenger ikke mange personer, da stiller vi ofte med 2-4 personer. Mens store oppdrag kan vi kjøre fullt team.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel17'} onChange={handleChange('panel17')}>
                                <AccordionSummary aria-controls="panel17d-content" id="panel17d-header">
                                    <Typography>Kan dere hjelpe meg dersom det viser seg at det er noe der?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Vi forsøker å finne forklaringer på alt som skjer og gi deg dokumentasjon på spesielle hendelser. Hvis du ønsker å forsøke noen form for "husrens" så er ikke dette noe vi gjør, men kan gi deg referanser til personer som gjør dette. Det er imidlertidig ikke noe vi kan anbefale eller påstå at vil ha noen virkning.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel18'} onChange={handleChange('panel18')}>
                                <AccordionSummary aria-controls="panel18d-content" id="panel18d-header">
                                    <Typography>Er det en måte vi kan prøve å kommunisere med "energiene" på?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Det er dette vi stadig jakter på, og forsøker å finne ut av ved hjelp av utstyr og forskjellige metoder som utvikler seg hele tiden. Mange forskjellige begrep blir brukt her, og "energier" er ett av de. Vi har fått respons både i form av lyd/stemmer og respons på forskjellig type måleinstrumenter. Men du trenger ikke noe mer komplisert enn et kamera eller en mobil for å ta lydopptak, for deretter å høre igjennom og se om du fanget opp noe du ikke oppfattet der og da.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel19'} onChange={handleChange('panel19')}>
                                <AccordionSummary aria-controls="panel19d-content" id="panel19d-header">
                                    <Typography>Hva er et nightvision kamera?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Det er et kamera som fanger opp IR (infrarødt) lys, slik at man kan filme i mørket. IR er utenfor spekteret som våre øyne ser, og oppfattes derfor kun på kamera.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel20'} onChange={handleChange('panel20')}>
                                <AccordionSummary aria-controls="panel20d-content" id="panel20d-header">
                                    <Typography>Bruker dere overvåkingskameraer?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Hva slags type utstyr vi benytter varierer fra sted til sted, hvor blant annet størrelse spiller inn. På store lokasjoner kan det være at vi rigger opp et fullt overvåkingssystem.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel21'} onChange={handleChange('panel21')}>
                                <AccordionSummary aria-controls="panel21d-content" id="panel21d-header">
                                    <Typography>Kan man bestille NGH klær?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Det er ikke mulig per i dag, men det er under vurdering.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel22'} onChange={handleChange('panel22')}>
                                <AccordionSummary aria-controls="panel22d-content" id="panel22d-header">
                                    <Typography>Hvorfor startet dere med ghosthunting?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Gruppen ble startet opp av ren nysgjerrighet. Når man hører mye historier som er veldig spesielle fra folk man kjenner så får man lyst til å oppleve det selv. Det var slik det hele startet. Du kan lese mer om historien bak Norwegian Ghost Hunters <Link href='/organisasjon'>her</Link>.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel23'} onChange={handleChange('panel23')}>
                                <AccordionSummary aria-controls="panel23d-content" id="panel23d-header">
                                    <Typography>Kan "energier" bli med dere hjem?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Dersom det er slik at energier er døde personer som går igjen, så virker det som om årsaken til det er at de har en tilknytning til enten stedet eller personene vi besøker. Logisk sett så er det derfor ingen grunn til at noe skal følge en tilfeldig person som besøker et sted med hjem. Men det her er ikke noe vi har noe fasitsvar på annet enn å bygge det på egen erfaring.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel24'} onChange={handleChange('panel24')}>
                                <AccordionSummary aria-controls="panel24d-content" id="panel24d-header">
                                    <Typography>Vi har et skoleprosjekt, stiller dere opp?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Ta kontakt med oss så tidlig som mulig så er det større sjanse for at vi kan få planlagt og avsatt tid til det. Vi stiller opp så sant vi har tid og mulighet, og det er noe vi har gjort ganske mye av.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel25'} onChange={handleChange('panel25')}>
                                <AccordionSummary aria-controls="panel25d-content" id="panel25d-header">
                                    <Typography>Hvor kan jeg kjøpe utstyr, selger dere?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Vi selger ikke utstyr, men det er mange forskjellige steder man kan kjøpe dette. Det viktigste man har er et videokamera og en god lyd-opptaker. Hvis man er ute etter utstyr laget spesifikt for spøkelsesjakt så finner man dette på for eksempel <Link href="https://www.ghoststop.com" target="_blank">ghoststop</Link> (vi har ingen avtale/inntekt på denne referansen).
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel26'} onChange={handleChange('panel26')}>
                                <AccordionSummary aria-controls="panel26d-content" id="panel26d-header">
                                    <Typography>Har dere vært på Lier?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Nei, vi besøker ikke steder uten å innhente tillatelse. Eierne av Lier har ikke vært interessert, og stedet har derfor ikke vært aktuelt. Dette stedet er nå også revet, og det er bygget nye blokkleiligheter der.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel27'} onChange={handleChange('panel27')}>
                                <AccordionSummary aria-controls="panel27d-content" id="panel27d-header">
                                    <Typography>Kan jeg bli medlem av Norwegian Ghost Hunters?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Vi består av flere team, og flere av teamene er ofte på utkikk etter nye ressurser som kan bidra. Det er en del krav du må oppfylle fra de forskjellige teamene. Du kan sende en forespørsel på <Link href='/kontakt'>kontakt</Link> siden.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </Grid></Grid>
            </ThemeProvider>
        </Box>
    );
}
