import React, { useEffect } from 'react';
import '../App.css';
import { AppBar, Box, createTheme, CssBaseline, Grid, Link, Stack, ThemeProvider, Typography } from '@mui/material';
import StickyFooter from '../components/Footer';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import Header from '../components/Header';

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function OrganizationTeam() {
    useEffect(() => {
        document.title = 'Informasjon - Norwegian Ghost Hunters';
    });
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppBar position="sticky" color="transparent">
                    <Header />
                </AppBar>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Forsiden
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                                href="#"
                            >
                                Medlemsinformasjon for team
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                            color="text.secondary"
                            gutterBottom
                        >
                            Medlemsinformasjon for team
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12} spacing={2}>
                        <Stack direction="row" alignItems="center" mt={2} mb={3} className="HeaderTitle">
                            <Typography sx={{ flex: 1 }} className='TitleText'>Generell informasjon</Typography>
                        </Stack>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            Norwegian Ghost Hunters er en frivillig organisasjon som i samarbeid med flere team som gjennomfører paranormale undersøkelser har utarbeidet noen felles retningslinjer for hvordan undersøkelser skal foregå. Formålet er å samle de teamene som gjennomfører undersøkelser på et seriøst nivå og med like metoder. Da kan oppdragsgivere vite at uansett hvilket team man kontakter som er i samarbeid med organisasjonen, så vil en undersøkelse bli gjennomført på samme måte. Det er også gunstig når man har oppdrag hvor flere av teamene samarbeider, at alle involverte personer og team gjør ting på samme måte.
                            <p></p>
                            Alle team som ønsker å være en del av samarbeidet må oppfylle alle krav som stilles på siden <Link href='/teamregler'>rutiner og regler</Link>, men bortsett fra dette så opererer teamene helt individuelt. NGH er bare en samarbeidspartner, og ikke en organisasjon som skal bestemme noe innad i teamene.
                            <Stack direction="row" alignItems="center" mt={2} mb={3} className="HeaderTitle">
                                <Typography sx={{ flex: 1 }} className='TitleText'>Fordeler</Typography>
                            </Stack>
                            <ul>
                                <li>Norwegian Ghost Hunters utvikler og drifter et IT-system ved navn ParaOffice som er et verktøy som hjelper team veldig med blant annet planlegging og gjennomføring av oppdrag, samt lagring av alle opptak med logging og klipp av funn. Alle medlemmer av team i samarbeidet får tilgang til å bruke systemet.</li>
                                <li>ParaOffice inkluderer også tilgang på register over interessante lokasjoner som ikke er publisert offentlig.</li>
                                <li>Henvendelser til Norwegian Ghost Hunters som gjelder alt fra media henvendelser til forespørsler om oppdrag, bli medlem i et team osv kan bli tildelt deres team.</li>
                                <li>Felles samarbeidsoppdrag, og også sosiale arrangement.</li>
                                <li>ID-Kort som er knyttet opp mot ParaOffice, og kan verifiseres med online sjekk.</li>
                                <li>Eksponering av oppdrag, videoer og teamet generelt på denne nettsiden.</li>
                                <li>Eksponering av teamet med informasjon om oppdrag gjennomført og opptak på <Link href='https://www.spokelseskartet.no/'>spøkelseskartet</Link>.</li>
                                <li>Interne kanaler på Discord, med varslinger fra ParaOffice, nyheter, etc.</li>
                                <li>Tilbud fra leverandører på utstyr.</li>
                                <li>Mulighet for publisering av nyheter på Norwegian Ghost Hunters Facebook side.</li>
                                <li>Mulighet for publisering av bilder på Norwegian Ghost Hunters Instagram konto.</li>
                            </ul>
                            <Stack direction="row" alignItems="center" mt={2} mb={3} className="HeaderTitle">
                                <Typography sx={{ flex: 1 }} className='TitleText'>Bli samarbeidsteam</Typography>
                            </Stack>
                            Det er noen kostnader for et team å være i et samarbeid, men NGH som hovedorganisasjon har ingen mål om å gå med overskudd. De kostnadene som dekkes av hvert enkelt team er blant annet for diskforbruk, backupløsninger og produksjon av ID-kort. Kostnadene varierer veldig fra team til team utifra mengde opptak som lagres, og hvor mange oppdrag som gjennomføres.<p></p>
                            Dersom du er leder av et team som ønsker å inngå samarbeid med Norwegian Ghost Hunters organisasjonen, så kan du benytte <Link href='/kontaktteam'>dette</Link> skjemaet for å sende inn en søknad. Det er viktig at du oppfyller alle krav som stilles før du sender inn søknad. Dersom søknaden blir avslått vil man få en karanteneperiode på ett år før man kan søke på nytt.
                        </Typography>
                    </Grid>
                </Grid>
                <StickyFooter />
            </ThemeProvider>
        </Box >
    );
}

export default OrganizationTeam;
