import React, { useEffect } from 'react';
import '../App.css';
import { AppBar, Box, createTheme, CssBaseline, Grid, Link, ThemeProvider, Typography } from '@mui/material';
import StickyFooter from '../components/Footer';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import Header from '../components/Header';

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function Equipment() {
    useEffect(() => {
        document.title = 'Utstyr - Norwegian Ghost Hunters';
    });
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppBar position="sticky" color="transparent">
                    <Header />
                </AppBar>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Forsiden
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                                href="#"
                            >
                                Teknisk utstyr
                            </Link>
                        </Breadcrumbs>
                    </Grid></Grid>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Teknisk utstyr
                        </Typography>
                        <p></p>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="text.primary"
                            gutterBottom
                        >
                            Sony FDR-AX53
                        </Typography>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            Dette er et 4K UHD videokamera med nattsyns funksjon slik at man kan se i mørket uten å lyse opp med vanlig lys, men kan bruke IR lys som kameraet kan fange opp men som vi selv ikke kan se. En teori er at det da kan være lettere å se skygger som kommer fra andre kilder enn eget lys, og at det ukjente kanskje har mer energi på kvelden etter å ha ladet opp i dagslyset.
                        </Typography>
                        <p></p>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="text.primary"
                            gutterBottom
                        >
                            Zoom Handy Recorder H6
                        </Typography>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            Dette er en høy kvalitets lydopptaker som vi bruker både under oppdrag og youtube produksjoner, en teori er at gjenferd kan manipulere en opptaker og legge igjen lyd eller stemme uten at de som er tilstede kan høre det. Eller at vi fanger opp lyder og stemmer vi faktisk hører under et oppdrag.
                        </Typography>
                        <p></p>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="text.primary"
                            gutterBottom
                        >
                            K2 EMF Meter
                        </Typography>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            K2 er en elektromagnetisk felt måler, denne enheten er brukt til å måle potensielt elektromagnetiske fenomener som kan ta plass under en etterforskning, men kan også brukes til å fastslå der det er naturlig elektromagnetiske felt, som feks dårlig isolerte strømkabler.

                            Det er en teori om at gjennferd kan manipulere elektromagnetiskefelt og dermed slå ut en K2 for å kommunisere siden K2 er et enkelt verktøy med lite funksjoner.

                            K2 detekterer radio frekvens ELF som er mellom 50 og 1,000 Hz og VLF som er mellom 1000 og 20000Hz
                        </Typography>
                        <p></p>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="text.primary"
                            gutterBottom
                        >
                            REM-Pod
                        </Typography>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            En Rem-pod er en boks med antenne som er basert på Theremin, og slik det fungerer er at når man kommer i nærheten av antennen så kommer det en lyd og et lys lyser opp, jo nærmere du kommer antennen jo kraftigere blir lyden og jo fler lys lyser opp, opp til 4 lys. Noen Rem-pod kommer også med tempratursensor som varsler med lyd og/eller lys hvis det blir en endring på +- 5c.
                            Teorien bak dette utstyret er at gjenferd kan bryte EM felten Thereminen skaper og kommunisere med både lyden og lysene på Rem-poden.
                        </Typography>
                        <p></p>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="text.primary"
                            gutterBottom
                        >
                            Spirit Box SB7
                        </Typography>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            Dette er en radio som har en funksjon som konstant skanner radio kanaler i et satt intervall slik at den aldri stopper på en radiostasjon. Teorien bak dette er at gjenferd kan manipulere hvit støy og da kunne prate via spiritboksen, og da når radioen aldri stopper på en kanal så kan man raskt eliminere om det skulle være radio eller ikke, og siden det er veldig lite aktivitet på vanlig radio frekvenser i norge i dag, så er dette et godt verktøy.
                        </Typography>
                        <p></p>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="text.primary"
                            gutterBottom
                        >
                            Paranormal Music Box (PMB)
                        </Typography>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            Paranormal Music Box er en enhet som med en ultralyd sensor, som fungerer som en ryggesensor på en bil, hvor den varsler hvis noe passerer innenfor sensorens rekkevidde, og den varsler da med et lys og en melodi. Når man setter den fra seg så vil det bruke noen sekunder på å kalibrere seg på atmosfæren rundt sensoren, hvis den atmosfæren endrer seg så vil den starte å varsle med lys og lyd. Teknisk: Den kan lese området opp til hvert 50mS, Sensoren opperer på 42 KHz, med en pulsbredde på 147uS/Inch.
                        </Typography>
                        <p></p>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="text.primary"
                            gutterBottom
                        >
                            Para4ce PMB
                        </Typography>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            PMB står for Paranormal Music Box, PMB er følsom for tempratur endring i forhold til rommet, slik at hvis det er en tempratur endring 1 meter foran PMB så vil den starte å spille melodi. Den reagerer ikke på EMF forstyrrelser, kun tempratur endring.
                        </Typography>
                        <p></p>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="text.primary"
                            gutterBottom
                        >
                            Paramid
                        </Typography>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            Dette er en Paranologies orginal ultrasonisk stereo sensor som detekterer bevegelse opp til 1.5 meter, den lager lyd og viser visuelt med et lcd display hvor langt unna objektet er.
                        </Typography>
                        <p></p>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="text.primary"
                            gutterBottom
                        >
                            360 Parascope
                        </Typography>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            Dette er en Paranologies Sensor, som følger statisk elektrisitet vannrett, som gjør at du kan følge med på lysene på enheten hvor energien beveger seg, i form av grønnt gult og rødt lys, og fargene kan brukes til å kommunisere under evp runder.
                        </Typography>
                        <p></p>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="text.primary"
                            gutterBottom
                        >
                            Electrascope
                        </Typography>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            Dette er Paranologies Electrascope som er håndholdt, hvor du kan følge EMF og og statiskefelt som beveger seg, slik at det er lett å finne kilden eller følge kilden, den viser rettningen i form av rødt og grønnt lys.
                        </Typography>
                        <p></p>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="text.primary"
                            gutterBottom
                        >
                            Thermascope
                        </Typography>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            Dette er Paranologies Thermascope som er en håndholdt tempraturmåler med 2 sensorer som konstant måler rom tempraturen, og blir tempraturen lavere så lyser den blått, og blir det høyere så lyser den rødt, og på den måten er det lett å detektere varme og kalde områder.
                        </Typography>
                        <p></p>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="text.primary"
                            gutterBottom
                        >
                            Ovlius III
                        </Typography>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            Ovlius er en taleboks med skjerm som inneholder bokstaver og ord som kan i forsjellige moduser, og teorien er at gjennferd kan manipulere enheten og få den til å si eller vise en bokstav eller ord utifra hvilken modus den står i, på den måten så kan gjennferdet lage settninger eller ord for å fortelle noe.
                        </Typography>
                        <p></p>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="text.primary"
                            gutterBottom
                        >
                            Mel Meter
                        </Typography>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            Mel Meter fungerer som en digital EMF måler med digital tempraturmåler, Mel meteret måler i frekvensene mellom 30Hz til 300Hz, Mel meter har en "burst" modus som gjør at det kan måle like kjapt som en K2 men du kan lettere lese nøyaktig måling.
                        </Typography>
                        <p></p>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="text.primary"
                            gutterBottom
                        >
                            Geophone
                        </Typography>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            Geophone er en seismograf som kan detektere vibrasjoner på stedet du setter den, og da vil den lyse opp med antall lys på hvor kraftig vibrasjonen er.
                        </Typography>
                        <p></p>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="text.primary"
                            gutterBottom
                        >
                            Annabelle
                        </Typography>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            Annabell er en dukke med EMF sensor som reagerer som K2, den har 4 lys for å indikere hvor EMF kommer fra, Hodet, magen, venstre og høyre arm.
                        </Typography>
                        <p></p>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="text.primary"
                            gutterBottom
                        >
                            Flux 2
                        </Typography>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            Flux 2 er en enhet med en sensor som måler distanse på venstre og høyre side, hvor den ene siden lyser rødt og andre grønt, det vil også komme lyd for å varsle. På den måter så gjør det enkelt å kunne stille spørsmål for å ha rødt som nei og grønt som ja.
                        </Typography>
                        <p></p>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="text.primary"
                            gutterBottom
                        >
                            SLS Kinect
                        </Typography>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            SLS Kintect er en enhet som bruker xbox kinect kamera, Kinect kameraet bruker laser og infrarødt lys for å kunne se eller tegne opp et menneske når man spiller spill, og teorien bak dette er da at man skal kunne se gjennferd som eventuelt skulle gått igjen, hvor kinect tegner opp en strek figur når den tror den ser et menneske/gjennferd. I programmet man bruker for å kunne se eventuelle figurer kan man se 3 vinduer, en live i farger, en i infrarødt, og en med laster hvor man da kan se strekfigurene.
                        </Typography>
                        <p></p>

                    </Grid>
                </Grid>
                <StickyFooter />
            </ThemeProvider>
        </Box>
    );
}

export default Equipment;
