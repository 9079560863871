import React, { useEffect, useState } from 'react';
import '../App.css';
import { AppBar, Box, Card, CardContent, CircularProgress, createTheme, CssBaseline, Grid, Link, ThemeProvider, Typography } from '@mui/material';
import StickyFooter from '../components/Footer';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import Header from '../components/Header';
import api from '../api';

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function Members() {
    useEffect(() => {
        document.title = 'Medlemmer - Norwegian Ghost Hunters';
    });
    const [isloadingmembers, setIsLoadingMembers] = useState(true);
    const [members, setMembers] = useState([]);
    useEffect(() => {
        api.get("/pub/users")
            .then((response: any) => {
                setIsLoadingMembers(false);
                const members = response.data;
                setMembers(members);
            })
            .catch((e: Error) => {
            });
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppBar position="sticky" color="transparent">
                    <Header />
                </AppBar>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Forsiden
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                                href="#"
                            >
                                Medlemmer
                            </Link>
                        </Breadcrumbs>
                    </Grid></Grid>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Medlemmer
                        </Typography>
                        <p></p>
                        <Typography
                            component="h1"
                            variant="h6"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Medlemmer
                        </Typography>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >

                            {!isloadingmembers ?
                                <Grid container>
                                    {
                                        members && members.map(({ userid, username  }: any) => {
                                            return <Grid item key={userid} xs={12} sm={12} md={4} lg={4} p={2}>
                                                <Card>
                                                    <CardContent>
                                                        <Typography gutterBottom variant="h6" component="h2">
                                                            {username}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                                ;
                                        })
                                    }
                                </Grid>
                                : <CircularProgress color="inherit" />}

                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <p></p>
                    </Grid>
                </Grid>
                <StickyFooter />
            </ThemeProvider>
        </Box>
    );
}

export default Members;
