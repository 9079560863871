import React, { FC, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { Alert, Button, Grid, Link, MenuItem, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import SendIcon from '@mui/icons-material/Send';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import api from '../api';

interface IFormInputs {
    contactteam: string;
    contactname: string;
    contactemail: string;
    contactcell: number;
    archtitle: string;
    archdescription: string;
}

const schema = yup.object().shape({
    contactname: yup.string().min(5).required(),
    contactcell: yup.number().moreThan(40000000).lessThan(99999999),
    contactemail: yup.string().email(),
    archtitle: yup.string().min(4).required(),
    archdescription: yup.string().min(10).required(),
});

const ContactScheme: FC = () => {
    const [viewscheme, setViewScheme] = useState(true);
    const [caseref, setCaseRef] = useState(null);
    const [isloadingteams, setIsLoadingTeams] = useState(true);
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        api.get("/pub/teams")
            .then((response: any) => {
                setIsLoadingTeams(false);
                const teams = response.data;
                setTeams(teams);
            })
            .catch((e: Error) => {
            });
    }, []);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInputs>({
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<IFormInputs> = (data) => {
        setOpen(!open);
        axios({
            method: "post",
            url: "https://api2.paraoffice.com:8080/api/pub/arch/contact",
            data: data,
            headers: { "Content-Type": "application/json" },
        })
            .then(response => {
                setOpen(false);
                setViewScheme(false);
                setCaseRef(response.data[0].archref);
            });
    }

    const [open, setOpen] = React.useState(false);

    return (
        viewscheme ?

            <Grid item xs={12} spacing={2} p={2}>
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
                <Stack direction="row" alignItems="center" gap={1} mt={2} mb={3} className="HeaderTitle">
                    <Typography sx={{ flex: 1 }} className='TitleText'>Kontaktskjema</Typography>
                </Stack>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} pb={2}>
                        <Grid item xs={12}>
                            <Alert severity="info">
                                <Typography color="text.secondary" gutterBottom>
                                    Hvis du har generelle spørsmål så vil du finne svar på mye på vår <Link href="/oss">ofte stilte spørsmål</Link> side.
                                </Typography>
                                <Typography color="text.secondary" gutterBottom>
                                    Har du forespørsel om vi kan utføre et oppdrag for deg, gå til <Link href="/kontaktoppdrag">denne</Link> siden.
                                </Typography>
                                <Typography color="text.secondary" gutterBottom>
                                    Lurer du på hvordan du kan bidra, eller ønsker å bli medlem av et team kan du lese mer om dette <Link href="/teammedlem">her</Link>.
                                </Typography>
                            </Alert>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Controller
                                name="contactteam"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        select
                                        fullWidth
                                        label="Mottaker team"
                                        value={value}
                                        defaultValue="1"
                                        onChange={onChange}
                                    >
                                        <MenuItem key='1' value='1'>
                                            Norwegian Ghost Hunters
                                        </MenuItem>
                                        {teams.map((option: any) => (
                                            <MenuItem key={option.teamid} value={option.teamid}>
                                                {option.teamname}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />
                            <Alert severity="info">Hvis du vil henvende deg til et konkret team kan du velge det her. Er du usikker så bruk "Norwegian Ghost Hunters".</Alert>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} pb={2}>

                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Controller
                                name="contactname"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Fullt Navn"
                                        variant="outlined"
                                        error={!!errors.contactname}
                                        helperText={errors.contactname ? errors.contactname?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Controller
                                name="contactemail"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="E-post"
                                        variant="outlined"
                                        error={!!errors.contactemail}
                                        helperText={errors.contactemail ? errors.contactemail?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Controller
                                name="contactcell"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Mobiltelefon"
                                        variant="outlined"
                                        error={!!errors.contactcell}
                                        helperText={errors.contactcell ? errors.contactcell?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} pb={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="archtitle"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Tittel"
                                        variant="outlined"
                                        error={!!errors.archtitle}
                                        helperText={errors.archtitle ? errors.archtitle?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid></Grid>
                    <Grid container spacing={2} pb={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="archdescription"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Henvendelse"
                                        variant="outlined"
                                        multiline
                                        rows="10"
                                        error={!!errors.archdescription}
                                        helperText={errors.archdescription ? errors.archdescription?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Button variant="outlined" type='submit' startIcon={<SendIcon />}>Send inn</Button>
                </form>
            </Grid>
            :
            <Typography
                component="h1"
                variant="h5"
                align="center"
                color="text.primary"
                gutterBottom
            >
                Takk for din henvendelse, vi besvarer så raskt som mulig (referanse: {caseref}).
            </Typography>
    );
};

export default ContactScheme;