import React, { useEffect, useState } from 'react';
import '../App.css';
import { Alert, AppBar, Box, Card, CardContent, CardMedia, CircularProgress, createTheme, CssBaseline, Grid, Link, ThemeProvider, Typography } from '@mui/material';
import StickyFooter from '../components/Footer';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import Header from '../components/Header';
import api from '../api';
import { useParams } from 'react-router-dom';
import CakeIcon from '@mui/icons-material/Cake';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import ImageIcon from '@mui/icons-material/Image';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function UserID() {
    const { idcard } = useParams();
    useEffect(() => {
        document.title = 'ID ' + idcard + ' - Norwegian Ghost Hunters';
    });
    const [isloadingmember, setIsLoadingMember] = useState(true);
    const [member, setMember] = useState<any[]>([]);
    useEffect(() => {
        api.get("/pub/userid/" + idcard)
            .then((response: any) => {
                const member = response.data;
                setMember(member);
                setIsLoadingMember(false);
                console.log(response.data);
            })
            .catch((e: Error) => {
            });
    }, [idcard]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppBar position="sticky" color="transparent">
                    <Header />
                </AppBar>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Forsiden
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="#"
                            >
                                ID #{idcard}
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            {!isloadingmember ?
                                <Grid container>
                                    <Grid item key={member[0].userid} xs={12} p={1}>
                                        <Grid container xs={12}>
                                            <Grid item xs={12} md={12} lg={6} xl={7} p={1}>
                                                <Card variant="outlined">
                                                    <Alert severity="success">Verifisert og gyldig ID</Alert>
                                                    <CardContent>
                                                        <Grid container>
                                                            <Grid item xs={12} md={4} lg={4} xl={3} p={1}>
                                                                <CardMedia
                                                                    style={{
                                                                        width: "150px",
                                                                        height: "150px"
                                                                    }}
                                                                    component="img"
                                                                    image={"https://poimg01.paraoffice.com/profiles/" + member[0].userhash + "_p.jpg"}
                                                                    alt="random"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={7} lg={8} xl={3} p={1}>
                                                                <Typography gutterBottom variant="h5" component="h2" color="text.secondary">
                                                                    {member[0]?.userfullname} ({member[0]?.age} år)
                                                                </Typography>
                                                                <Typography variant="body1" component="h2" color="text.secondary">
                                                                <Link href={"/team/" + member[0].teamshortname}>{member[0]?.teamname}</Link> - {member[0]?.teamaccesstitle}<br />
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                                                Medlem i {member[0]?.memberyears} år, {member[0]?.events} oppdrag
                                                                </Typography>
                                                                <Typography gutterBottom variant="body2" color="text.secondary" className='texticontypo'>
                                                                    <CakeIcon className='texticon' /> {member[0]?.userbirthdisp}
                                                                </Typography>
                                                                <Typography gutterBottom variant="body2" color="text.secondary" className='texticontypo'>
                                                                    <HomeIcon className='texticon' /> {member[0]?.usercity}
                                                                </Typography>
                                                                {member[0]?.userphone &&
                                                                    <Typography gutterBottom variant="body2" color="text.secondary" className='texticontypo'>
                                                                        <PhoneIcon className='texticon' /> <Link href={"tel:" + member[0]?.userphone}>{member[0]?.userphone}</Link>
                                                                    </Typography>
                                                                }
                                                                {member[0]?.useremail &&
                                                                    <Typography gutterBottom variant="body2" color="text.secondary" className='texticontypo'>
                                                                        <EmailIcon className='texticon' /> <Link href={"mailto:" + member[0]?.useremail}>{member[0]?.useremail}</Link>
                                                                    </Typography>
                                                                }
                                                                {member[0]?.userfacebook &&
                                                                    <Typography gutterBottom variant="body2" color="text.secondary" className='texticontypo'>
                                                                        <FacebookIcon className='texticon' /> <Link href={"https://facebook.com/" + member[0]?.userfacebook} target='_blank'>{member[0]?.userfacebook}</Link>
                                                                    </Typography>
                                                                }
                                                                {member[0]?.userinstagram &&
                                                                    <Typography gutterBottom variant="body2" color="text.secondary" className='texticontypo'>
                                                                        <InstagramIcon className='texticon' /> <Link href={"https://instagram.com/" + member[0]?.userinstagram} target='_blank'>{member[0]?.userinstagram}</Link>
                                                                    </Typography>
                                                                }
                                                                {member[0]?.usersnapchat &&
                                                                    <Typography gutterBottom variant="body2" color="text.secondary" className='texticontypo'>
                                                                        <ImageIcon className='texticon' /> {member[0]?.usersnapchat}
                                                                    </Typography>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                : <CircularProgress color="inherit" />
                            }
                        </Typography>
                    </Grid>
                </Grid>
                <StickyFooter />
            </ThemeProvider>
        </Box>
    );
}
export default UserID;