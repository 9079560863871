import React, { useEffect, useState } from 'react';
import api from '../api';
import { AppBar, Box, Breadcrumbs, Card, CardMedia, CircularProgress, createTheme, CssBaseline, Dialog, Grid, IconButton, LinearProgress, LinearProgressProps, Link, Stack, Tab, Tabs, ThemeProvider, Toolbar, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import FileSize from '../components/FileSize';
import SecondsToHuman from '../components/SecondsToHuman';
import Header from '../components/Header';
import HomeIcon from '@mui/icons-material/Home';
import ApartmentIcon from '@mui/icons-material/Apartment';
import TagIcon from '@mui/icons-material/Tag';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import { SpectrumVisualizer, SpectrumVisualizerTheme } from 'react-audio-visualizers';
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Image } from "react-grid-gallery";

export interface CustomImage extends Image {
    original: string;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress color="success" variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function CaseDetails() {
    const { eventpublicurl } = useParams();
    useEffect(() => {
        document.title = 'Oppdrag ' + eventpublicurl + ' - Norwegian Ghost Hunters';
    });
    const [isloadingcases, setIsLoadingCases] = useState(true);
    const [isloadingimages, setisloadingimages] = useState(true);
    const [cases, setCases] = useState<any[]>([]);
    const [caseprogress, setCaseProgress] = useState(0);
    const [value, setValue] = useState(0);
    const [cliphash, setClipHash] = useState('');
    const [clipfile, setClipFile] = useState('');
    const [clipeqname, setClipEqname] = useState('');
    const [clipisvideo, setClipIsVideo] = useState(true);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue === 2) {
            updateimagegallery();
        }
        setValue(newValue);
    };
    const [open, setOpen] = React.useState(false);
    const [eventimages, setEventImages] = useState<any[]>([]);
    const [eventimagestmp, setEventImagestmp] = useState<any[]>([]);

    const [index, setIndex] = useState(-1);
    const currentImage = eventimages[index];
    const nextIndex = (index + 1) % eventimages.length;
    const nextImage = eventimages[nextIndex] || currentImage;
    const prevIndex = (index + eventimages.length - 1) % eventimages.length;
    const prevImage = eventimages[prevIndex] || currentImage;
    const handleClickImage = (index: number, item: CustomImage) => setIndex(index);
    const handleCloseImage = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);



    function handleClickOpen(chash: any, trname: any, effilename: any, effiletypeid: any) {
        setClipHash(chash);
        setClipFile(effilename);
        setClipEqname(trname);
        if (effiletypeid === 2) {
            setClipIsVideo(false);
        }
        else {
            setClipIsVideo(true);
        }
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        api.get("/pub/event/" + eventpublicurl)
            .then(async (response: any) => {
                setIsLoadingCases(false);
                const cases = response.data;
                setCases(cases);
                setEventImages([]);
                setEventImagestmp([]);
                setisloadingimages(true);
                await response.data[0].images.map(({ folderhash, imagehash, eimheight, eimwidth }: any) => {
                    eimheight = eimheight || 320;
                    eimwidth = eimwidth || 320;
                    eventimagestmp.push({ src: "https://poimg01.paraoffice.com/events/" + response.data[0].eventhash + "/" + folderhash + "/" + imagehash + "_t.jpg", original: "https://poimg01.paraoffice.com/events/" + response.data[0].eventhash + "/" + folderhash + "/" + imagehash + "_n.jpg", width: eimwidth, height: eimheight });
                });
                setEventImages(eventimagestmp);
                if (cases[0].effilesnewlen == null) {
                    setCaseProgress(100);
                }
                else {
                    var evdone = cases[0].eventfileslen - cases[0].effilesnewlen;
                    var eventperc = evdone / cases[0].eventfileslen * 100;
                    setCaseProgress(eventperc);
                }
            })
            .catch((e: Error) => {
            });
    }, [eventpublicurl]);


    async function updateimagegallery() {
        setEventImages([]);
        setEventImagestmp([]);
        setisloadingimages(true);
        await cases[0].images.map(({ folderhash, imagehash, eimheight, eimwidth }: any) => {
            eimheight = eimheight || 320;
            eimwidth = eimwidth || 320;
            eventimagestmp.push({ src: "https://poimg01.paraoffice.com/events/" + cases[0].eventhash + "/" + folderhash + "/" + imagehash + "_t.jpg", original: "https://poimg01.paraoffice.com/events/" + cases[0].eventhash + "/" + folderhash + "/" + imagehash + "_n.jpg", width: eimwidth, height: eimheight });
        });
        setEventImages(eventimagestmp);
    }


    useEffect(() => {
        setisloadingimages(false);
    }, [eventimages]);

    return (
        <Box sx={{ width: '100%' }}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppBar position="sticky" color="transparent">
                    <Header />
                </AppBar>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Forsiden
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/oppdrag"
                            >
                                Oppdrag
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                                href="#"
                            >
                                {cases[0]?.eventname}
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Typography
                    component="h1"
                    variant="h5"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    {cases[0]?.eventname}
                </Typography>
                <p></p>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="tabs" variant="fullWidth">
                        <Tab label="Informasjon" {...a11yProps(0)} />
                        <Tab label={"Funn (" + cases[0]?.eventlogs + ")"} {...a11yProps(1)} />
                        <Tab label={"Bilder (" + cases[0]?.eventimagesnum + ")"} {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Box sx={{ width: '100%' }}>
                        {!isloadingcases ?
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4} lg={3} xl={2} p={1}>
                                    <Card>
                                        <CardMedia
                                            component="img"
                                            image={"https://poimg01.paraoffice.com/event/" + cases[0]?.eventhash + ".png"}
                                            alt={cases[0].eventname}
                                            sx={{ maxHeight: 250 }}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={8} lg={9} xl={10} pl={2} pt={1} pb={1} pr={1}>
                                    <Typography
                                        color="text.secondary"
                                        paragraph
                                        gutterBottom
                                    >
                                        {cases[0].eventpublicinfo}
                                    </Typography>
                                    <Box sx={{ width: '100%' }}>
                                        <LinearProgressWithLabel value={caseprogress} />
                                    </Box>
                                    <Typography
                                        color="text.secondary"
                                        paragraph
                                        gutterBottom
                                    >
                                        Opptak: {SecondsToHuman(cases[0].eventfileslen)} ({FileSize(cases[0].eventfilessize)})<br />
                                        Publiserte funn: {cases[0].eventlogs}<p></p>
                                        Status: {cases[0]?.eventstatus}<br />
                                    </Typography>
                                </Grid>
                                <Grid container spacing={2} pr={2} pt={4} pl={2}>
                                    <Grid item xs={12}>
                                        <Typography
                                            component="h1"
                                            variant="h6"
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            Deltakere
                                        </Typography>
                                    </Grid>
                                    {
                                        cases && cases[0].investigators.map(({ userhash, userdispname }: any) => {
                                            return <span key={userhash} style={{ margin: 5 }}>
                                                <Card sx={{ maxWidth: 100 }}>
                                                    <CardMedia
                                                        sx={{ maxHeight: 100 }}
                                                        component="img"
                                                        image={"https://poimg01.paraoffice.com/profiles/" + userhash + "_p.jpg"}
                                                        alt="random"
                                                    />
                                                    <div className="MemberName">{userdispname}</div>
                                                </Card>
                                            </span>
                                        })
                                    }
                                </Grid>
                            </Grid>
                            : <CircularProgress color="inherit" />
                        }
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Box sx={{ width: '100%' }}>
                        {!isloadingcases ?
                            cases && cases[0].logs.map(({ eflid, efldescriptionpublic, eflhash, eflhashpub, tags, areas, trname, effilename, effiletypeid }: any) => {
                                return <Grid item xs={12} p={1}>
                                    <Card key={eflid} variant="elevation" className="CardLink" onClick={() => handleClickOpen(eflhash, trname, effilename, effiletypeid)}>
                                        <Grid container>
                                            <Grid item xs={12} md={4} lg={3} xl={2} p={1}>
                                                {effiletypeid === 2 ?
                                                    <img src="../images/audio.png" style={{ height: "120px" }} alt='Lydklipp' />
                                                    :
                                                    <img src={"https://media.ghosthunters.no/" + cases[0]?.eventhash + "/" + eflhash + ".mp4.jpg"} style={{ height: "120px" }} alt='Videoklipp' />
                                                }
                                                <br />
                                                <Typography className='Equipment'>{trname}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={8} lg={9} xl={10} pl={2} pt={1} pb={1} pr={1}>
                                                <Typography
                                                    color="text.secondary"
                                                    paragraph
                                                >
                                                    {efldescriptionpublic}
                                                </Typography>
                                                <Typography
                                                    color="text.secondary"
                                                    paragraph
                                                >
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Stack direction="row" alignItems="center" gap={1} className='EvidenceExtras'>
                                                                <Inventory2Icon className='EvidenceExtras' />
                                                                {effilename}
                                                            </Stack>
                                                            <Grid item xs={12}>
                                                                <Stack direction="row" alignItems="center" gap={1}>
                                                                    <ApartmentIcon className='EvidenceExtras' />
                                                                    {areas.map(({ laid, laname }: any) => {
                                                                        return <span key={laid} className='TagBox'>{laname}</span>
                                                                    })
                                                                    }
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Stack direction="row" alignItems="center" gap={1}>
                                                                    <TagIcon className='EvidenceExtras' />
                                                                    {tags.map(({ ltid, ltname }: any) => {
                                                                        return <span key={ltid} className='TagBox'>{ltname}</span>
                                                                    })
                                                                    }
                                                                </Stack>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            })
                            : <CircularProgress color="inherit" />
                        }
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Grid container>
                        <Grid item xs={12}>
                            {(eventimages) &&
                                <Box>
                                    <Gallery
                                        images={eventimages}
                                        onClick={handleClickImage}
                                        enableImageSelection={false}
                                    />
                                    {!!currentImage && (
                                        /* @ts-ignore */
                                        <Lightbox
                                            mainSrc={currentImage.original}
                                            imageTitle={currentImage.caption}
                                            mainSrcThumbnail={currentImage.src}
                                            nextSrc={nextImage.original}
                                            nextSrcThumbnail={nextImage.src}
                                            prevSrc={prevImage.original}
                                            prevSrcThumbnail={prevImage.src}
                                            onCloseRequest={handleCloseImage}
                                            onMovePrevRequest={handleMovePrev}
                                            onMoveNextRequest={handleMoveNext}
                                        />
                                    )}
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </TabPanel>
                <Dialog
                    fullWidth
                    maxWidth="lg"
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <Typography sx={{ flex: 1 }}>{clipeqname} - {clipfile}</Typography>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    {!isloadingcases ?
                        clipisvideo ?
                            <video width="100%" height="100%" preload='auto' controls>
                                <source src={"https://media.ghosthunters.no/" + cases[0].eventhash + "/" + cliphash + ".mp4"} type="video/mp4" />
                            </video>
                            :
                            <SpectrumVisualizer
                                audio={"https://media.ghosthunters.no/" + cases[0].eventhash + "/" + cliphash + ".wav"}
                                theme={SpectrumVisualizerTheme.squaredBars}
                                colors={['#607D8B', '#607D8B']}
                                iconsColor="#777"
                                backgroundColor="black"
                                showMainActionIcon
                                showLoaderIcon
                                highFrequency={8000}
                            />
                        : null
                    }
                </Dialog>
            </ThemeProvider>
        </Box>
    );
}