import React, { useEffect } from 'react';
import '../App.css';
import { AppBar, Box, createTheme, CssBaseline, Grid, Link, Stack, ThemeProvider, Typography } from '@mui/material';
import StickyFooter from '../components/Footer';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import Header from '../components/Header';

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function Organization() {
    useEffect(() => {
        document.title = 'Informasjon - Norwegian Ghost Hunters';
    });
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppBar position="sticky" color="transparent">
                    <Header />
                </AppBar>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Forsiden
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                                href="#"
                            >
                                Om Norwegian Ghost Hunters
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                            color="text.secondary"
                            gutterBottom
                        >
                            Om Norwegian Ghost Hunters
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12} spacing={2}>
                        <Stack direction="row" alignItems="center" mt={2} mb={3} className="HeaderTitle">
                            <Typography sx={{ flex: 1 }} className='TitleText'>Introduksjon</Typography>
                        </Stack>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            Norwegian Ghost Hunters er en organisasjon som ble startet opp i 2009, men ikke registrert offisielt som en frivillig organisasjon før i 2021. Det startet opp som ett team, men det har etterhvert blitt etablert flere team i Norge som vi har sett nytten av å samarbeide med.<p></p>
                            Formålet med en felles organisasjon er å samle de seriøse aktørene i miljøet som oppfyller standard <Link variant="body1" href="/teamregler">rutiner og regler</Link> for utførelse og gjennomføring av oppdrag. På den måten vet de som ønsker en undersøkelse av en lokasjon hvordan ting blir utført, og at det blir utført av en seriøs aktør.
                            Det opprinnelige Norwegian Ghost Hunters teamet er nå organisert som flere offisielle team under hovedorganisasjonen, Norwegian Ghost Hunters Oslo og Norwegian Ghost Hunters Bergen. I tillegg så er hovedorganisasjonen åpen for samarbeid med de teamene som ønsker å operere på samme måte som oss. Informasjon om hva det innebærer for et team å være med i samarbeidet med Norwegian Ghost Hunters finner du på <Link href='/organisasjonteam'>denne siden</Link>.<p></p>
                            Liste over alle offisielle team, og team som samarbeider med organisasjonen finner du <Link variant="body1" href="/team">her</Link>.
                            <p></p>
                            Dersom du har lyst til å bli med i et team så kan du lese mer om dette <Link href='/teammedlem'>her</Link>.<p></p>
                            Hvis du har spørsmål til oss som du ikke finner noe svar på her, så har vi også en side med <Link variant="body1" href="/oss">ofte stilte spørsmål</Link> som besvarer mange spørsmål. Du kan også gå til <Link href='/kontakt'>kontakt oss</Link> siden for å sende oss en henvendelse.
                            <Stack direction="row" alignItems="center" gap={1} mt={5} mb={3} className="HeaderTitle">
                                <Typography sx={{ flex: 1 }} className='TitleText'>Historie</Typography>
                            </Stack>
                            <Typography
                                component="h1"
                                variant="h6"
                                align="center"
                                color="text.secondary"
                                gutterBottom
                            >
                                2009
                            </Typography>
                            Når vi startet opp i 2009 så var det en vennegjeng som tidligere ikke hadde hatt noen opplevelser eller erfaring med paranormale hendelser. Vi fant ut at vi ville dra ut på tur for å prøve å oppleve dette som mange andre snakket om. Det startet opp med en tur til Nes Kirkeruiner, hvor vi fikk noen svake indikasjoner på at det kanskje kunne være noe i disse historiene. Nysgjerrigheten bare vokste etter denne turen, og det utviklet seg veldig fort når vi begynte å publisere offentlig hva vi drev med. Fort så begynte det å komme mange forespørsler fra personer som ønsket å bli en del av teamet vårt. Teamet ble bygget opp med personer som har forskjellige yrkesfaglig bakgrunn, og litt forskjellige meninger om det paranormale. Dette for å skape et internt miljø hvor vi kan diskutere opplevelser og funn på opptak med ulikt grunnlag for meninger.<p></p>
                            Vi fant fort ut at vi ønsket å undersøke steder under litt mer kontrollerte omgivelser innendørs, så da kontaktet vi Blaker Skanse som også Åndenes Makt hadde besøkt tidligere og fikk tilgang. På dette tidspunktet hadde vi etablert oss i sosiale medier, og ble da kontaktet av en journalist som ønsket å følge med oss. Dette resulterte i en artikkel og <Link href='https://tv.vg.no/video/35970/bli-med-paa-spoekelsesjakt-med-norwegian-ghost-hunters' target='_blank'>video på VGTV</Link>.
                            <p></p>
                            <Grid container>
                                <Grid item xs={4}>
                                    <img src='/images/blakersk1.jpg' className='img-fluid' alt='Blaker Skanse utsiden'></img><br />
                                    <i>Utsiden av Blaker Skanse</i>
                                </Grid>
                                <Grid item xs={4}>
                                    <img src='/images/blaker1.jpg' className='img-fluid' alt='Blaker Skanse HQ'></img><br />
                                    <i>Basen vi etablerte</i>
                                </Grid>
                                <Grid item xs={4}>
                                    <img src='/images/blakersk2.jpg' className='img-fluid' alt='Blaker Skanse undersøkelser'></img><br />
                                    <i>Undersøkelser pågår</i>
                                </Grid>
                            </Grid>
                            <p></p>
                            <Typography
                                component="h1"
                                variant="h6"
                                align="center"
                                color="text.secondary"
                                gutterBottom
                            >
                                2010
                            </Typography>
                            I 2010 skjedde det stor utvikling av teamet, og vi var blant annet med på Fredriksten Festning når Ghost Hunters International TV-serien undersøkte festningen. Resultatet finnes i sesong 2, episode 17 av TV-serien.
                            <p></p>
                            <Grid container>
                                <Grid item xs={4}>
                                    <img src='/images/nghghi.jpg' className='img-fluid' alt='NGH og GHI medlemmer'></img><br />
                                    <i>NGH og GHI medlemmer</i>
                                </Grid>
                                <Grid item xs={4}>
                                    <img src='/images/nghghi2.jpg' className='img-fluid' alt='TV innspilling pågår'></img><br />
                                    <i>Innspilling pågår</i>
                                </Grid>
                                <Grid item xs={4}>
                                    <img src='/images/nghghi3.jpg' className='img-fluid' alt='TV innspilling pågår'></img><br />
                                    <i>Innspilling pågår</i>
                                </Grid>
                            </Grid>
                            <p></p>
                            <Grid container>
                                <Grid item xs={8}>
                                    Vi ble også invitert til å delta i TV-serien til Dansken og Fingern. Dette resulterte også i et veldig bra innslag i serien, som endte opp med å bli det lengste klippet i sesongen på grunn av at innholdet ble såpass interessant.
                                </Grid>
                                <Grid item xs={4}>
                                    <img src='/images/dogf.jpg' className='img-fluid' alt='Esben "Dansken" Selvig, og Thomas "Fingern" Gullestad'></img><br />
                                    <i>Esben "Dansken" Selvig, og Thomas "Fingern" Gullestad</i>
                                </Grid>

                            </Grid>
                            <p></p>

                            Vår første tur til Sverige skjedde også i 2010. Vi tok turen til Bålsta Gästgivaregård ikke så langt unna Stockholm, og ble litt overrasket når vi fikk overlatt hele hotellet til oss selv. <p></p>
                            <Typography
                                component="h1"
                                variant="h6"
                                align="center"
                                color="text.secondary"
                                gutterBottom
                            >
                                2011
                            </Typography>
                            Utviklingen til NGH fortsatte i 2011 når vi for første gang hadde et arrangement for publikum på Fredrikstad Stadion, som også ble direktesendt på radio. I forkant av arrangementet hadde vi også gjort en større undersøkelse av stedet som også nådde nyhetene utenfor Norge, og ble omtalt i den svenske nasjonalavisen <Link href='https://www.expressen.se/sport/fotboll/barsom-om-spokena-ring-ghostbusters/' target='_blank'>expressen.se</Link>.
                            <p></p>
                            <Grid container>
                                <Grid item xs={4}>
                                    <img src='/images/ffk1.jpg' className='img-fluid' alt='Fredrikstad stadion'></img><br />
                                    <i>Fredrikstad stadion</i>
                                </Grid>
                                <Grid item xs={4}>
                                    <img src='/images/ffk2.jpg' className='img-fluid' alt='Utstyr vi brukte'></img><br />
                                    <i>Utstyr vi brukte</i>
                                </Grid>
                                <Grid item xs={4}>
                                    <img src='/images/ffk3.jpg' className='img-fluid' alt='Overvåking'></img><br />
                                    <i>Overvåking</i>
                                </Grid>
                            </Grid>
                            <p></p>
                            Vi begynte også å planlegge og gjennomføre lengre turer hvor vi reiste til andre deler av landet og samlet opp mange oppdrag i løpet av en kort tidsperiode. En uke var vi blant annet på sørlandet og gjennomførte mange oppdrag i Lindesnes/Kristiansand-området. Dagbladet var også med oss på et av oppdragene og lagde en større reportasje i magasinet. Dette var også året vi besøkte Trudvang Gjestegård i Larvik første gang, et sted vi har funnet veldig interessant og besøkt mange ganger i ettertid. En artikkel med informasjon og historier om stedet kan leses <Link href='https://www.magic.no/magic-magasin/reportasjer/fortidens-skygger-hviler-over-trudvang-gjestegaard' target='_blank'>her</Link>.<p></p>
                            <Typography
                                component="h1"
                                variant="h6"
                                align="center"
                                color="text.secondary"
                                gutterBottom
                            >
                                2012
                            </Typography>
                            I 2012 ble vi invitert av Oslo kommune til å delta på kulturnatt arrangementet. Da samarbeidet vi med Oslo Ladegård om et åpent arrangement der som ble en stor suksess med litt for mange folk, så en del måtte avvises i døren pga kapasitet. Det var også året hvor vi hadde vår første tur til Luster Sanatorium som vi har vært tilbake til og hatt mange prosjekter på i ettertid.
                            <p></p>
                            <Typography
                                component="h1"
                                variant="h6"
                                align="center"
                                color="text.secondary"
                                gutterBottom
                            >
                                2013
                            </Typography>
                            I 2013 tok vi turen til Bærums Verk sammen med Petter Schjerven for en produksjon av en episode til TV-programmet "Typisk Deg". Her undersøkte vi blant annet vandrehistorien om den berømte telefonen som sies å ringe hver natt. Vi ble litt overrasket over at dette faktisk skjedde ettersom det er veldig få slike vandrehistorier som vi faktisk opplever å få bekreftet at faktisk skjer.
                            <p></p>
                            <Grid container>
                                <Grid item xs={4}>
                                    <img src='/images/nghtd2.jpg' className='img-fluid' alt='Geir (NGH) og Petter Schjerven'></img><br />
                                    <i>Geir (NGH) og Petter Schjerven</i>
                                </Grid>
                                <Grid item xs={4}>
                                    <img src='/images/nghtd1.jpg' className='img-fluid' alt='NGH van'></img><br />
                                    <i>NGH vanen</i>
                                </Grid>
                                <Grid item xs={4}>
                                    <img src='/images/nghtd3.jpg' className='img-fluid' alt='Petter Schjerven på termokamera'></img><br />
                                    <i>Petter Schjerven på termokamera</i>
                                </Grid>
                            </Grid>
                            <p></p>
                            <Typography
                                component="h1"
                                variant="h6"
                                align="center"
                                color="text.secondary"
                                gutterBottom
                            >
                                2014
                            </Typography>
                            2014 ble et nytt veldig aktivt år, hvor vi blant annet hadde en uke i Trondheim hvor vi gjennomførte fem store oppdrag. Hegra festning var en av lokasjonene som vi også gjentatte ganger har besøkt i ettertid, også for å avholde et arrangement.<p></p>
                            <Typography
                                component="h1"
                                variant="h6"
                                align="center"
                                color="text.secondary"
                                gutterBottom
                            >
                                2015
                            </Typography>
                            I 2015 ble Luster Sanatorium stedet vi fokuserte mye på. Vi rigget opp mange overvåkingskamera som stod der oppe permanent i de tre neste årene. Fremdeles sitter vi på ekstremt store mengder med opptak fra dette prosjektet som vi ikke har sett igjennom.<p></p>
                            <p></p>
                            <Grid container>
                                <Grid item xs={4} pr={2}>
                                    <img src='/images/luster1.jpg' className='img-fluid' alt='Luster Sanatorium'></img><br />
                                    <i>Luster Sanatorium</i>
                                </Grid>
                                <Grid item xs={4}>
                                    <img src='/images/luster2.jpg' className='img-fluid' alt='Overvåking Kjeller'></img><br />
                                    <i>Overvåking Kjeller</i>
                                </Grid>
                                <Grid item xs={4}>
                                    <img src='/images/luster3.jpg' className='img-fluid' alt='Overvåking ute'></img><br />
                                    <i>Overvåking ute</i>
                                </Grid>
                            </Grid>
                            <p></p>
                            <Typography
                                component="h1"
                                variant="h6"
                                align="center"
                                color="text.secondary"
                                gutterBottom
                            >
                                2016
                            </Typography>
                            2016 ble et år med mange private oppdrag, men vi fikk også gjennomført et arrangement hos Norsk Jernbanemuseum i forbindelse med kulturnatt.<p></p>
                            <Typography
                                component="h1"
                                variant="h6"
                                align="center"
                                color="text.secondary"
                                gutterBottom
                            >
                                2017
                            </Typography>
                            I 2017 begynte vi å bli involvert i veldig mange spennende prosjekter. Det største prosjektet var med de svenske youtuber profilene Jocke & Jonna som tok kontakt med oss og ville finne et sted å lage en produksjon i Norge. Vi inviterte de til Luster Sanatorium, hvor de tok med seg det svenske teamet LaxTon og en god del kjente personligheter i Sverige.
                            <p></p>
                            <Grid container>
                                <Grid item xs={4}>
                                    <img src='/images/joj1.jpg' className='img-fluid' alt='NGH og LaxTon'></img><br />
                                    <i>NGH og LaxTon</i>
                                </Grid>
                                <Grid item xs={4}>
                                    <img src='/images/joj2.jpg' className='img-fluid' alt='Basen på Luster Sanatorium'></img><br />
                                    <i>Basen på Luster Sanatorium</i>
                                </Grid>
                                <Grid item xs={4}>
                                    <img src='/images/joj3.jpg' className='img-fluid' alt='Innleid helikopter for prosjektet'></img><br />
                                    <i>Innleid helikopter for prosjektet</i>
                                </Grid>
                            </Grid>
                            <p></p>
                            Vi hadde også to prosjekter i forbindelse med Halloween. Det ene var det vi selv som organiserte. Det var et arrangement på Trudvang Gjestegård i Larvik 27-29 oktober hvor vi inviterte flere team fra Norge og Sverige. I tillegg var det også mulighet for noen privatpersoner å delta.
                            <p></p>
                            <Grid container>
                                <Grid item xs={4}>
                                    <img src='/images/tev1.jpg' className='img-fluid' alt='UPAI foredrag'></img><br />
                                    <i>UPAI foredrag</i>
                                </Grid>
                                <Grid item xs={4}>
                                    <img src='/images/tev2.jpg' className='img-fluid' alt='SCS Sweden'></img><br />
                                    <i>SCS Sweden</i>
                                </Grid>
                                <Grid item xs={4}>
                                    <img src='/images/tev3.jpg' className='img-fluid' alt='Publikum'></img><br />
                                    <i>Publikum</i>
                                </Grid>
                            </Grid>
                            <p></p>
                            Vi har også bistått litt med <Link href='https://www.spøkelseshuset.no' target='_blank'>spøkelseshuset.no</Link> sitt arrangement. De lager Norges største spøkelseshus i forbindelse med Halloween hvert år.<p></p>
                            <Typography
                                component="h1"
                                variant="h6"
                                align="center"
                                color="text.secondary"
                                gutterBottom
                            >
                                2018
                            </Typography>
                            Premierefest for en videoproduksjon av Paranormal Seekers Norway på Trudvang Gjestegård. Resultatet kan du se på youtube kanalen deres.
                            <p></p>
                            <Grid container>
                                <Grid item xs={4}>
                                    <img src='/images/tp1.jpg' className='img-fluid' alt='Bilde fra premierefest'></img><br />
                                    <i>Bilde fra premierefest</i>
                                </Grid>
                                <Grid item xs={4}>
                                    <img src='/images/tp2.jpg' className='img-fluid' alt='PSN og NGH bil ved inngangen'></img><br />
                                    <i>PSN og NGH bil ved inngangen</i>
                                </Grid>
                            </Grid>
                            <p></p>
                            Som følge av vårt arrangement på Trudvang hvor vi inviterte svenske team fikk vi tilbake flere invitasjoner til Sverige. Vi deltok blant annet på National Ghost Hunting Day på Hensbacka Herregård sammen med Ghost Hunters Sweden (nå PI). SCS Sweden arrangerte også et lignende arrangement som vi gjorde på Trudvang på Østerbybruks Herregård i Sverige hvor flere team og besøkende ble invitert. Her deltok blant annet Spökjakt Sverige, Svenska spökkartan, SCS Sweden, Ghostly Fun og U.A.E. Sverige.
                            <p></p>
                            <Grid container>
                                <Grid item xs={4}>
                                    <img src='/images/osterby.jpg' className='img-fluid' alt='Østerbybruks teams'></img><br />
                                    <i>Flere team foran Østerbybruks</i>
                                </Grid>
                            </Grid>
                            <p></p>
                            <Typography
                                component="h1"
                                variant="h6"
                                align="center"
                                color="text.secondary"
                                gutterBottom
                            >
                                2019
                            </Typography>
                            Siden oppstarten av NGH så har ikke vi hatt noe særlig fokus på å underholde et publikum i form av å vise frem hva vi faktisk gjør. Men i 2019 bestemte vi oss for å begynne å lage noen videoproduksjoner samt å involvere gjester, som da blant annet har vært forskjellige kjendiser fra reality-tv miljøet. Det er både for at det skaper litt mer oppmerksomhet, samt at personer utenfor teamet vårt får et innblikk i hva vi gjør. Vi inviterte med to tidligere deltakere av Paradise Hotel, Morten Botten og Sofie Nilsen på en tur til et av de største stedene vi har vært, Dale Mentalsykehus utenfor Sandnes.
                            <p></p>
                            <Grid container>
                                <Grid item xs={4}>
                                    <img src='/images/msdale1.jpg' className='img-fluid' alt='NGH, Morten og Sofie'></img><br />
                                    <i>NGH, Morten og Sofie</i>
                                </Grid>
                                <Grid item xs={4}>
                                    <img src='/images/msdale2.jpg' className='img-fluid' alt='Basen'></img><br />
                                    <i>Basen</i>
                                </Grid>
                                <Grid item xs={4}>
                                    <img src='/images/msdale3.jpg' className='img-fluid' alt='Hovedbygget'></img><br />
                                    <i>Hovedbygget</i>
                                </Grid>
                            </Grid>
                            <p></p>
                            Det har også blitt noen invitasjoner til podcast produksjoner. Vi hadde et samarbeid med Fabel med en innspilling på Cafe Celsius i Oslo i serien "Iskalde Grøss". Resultatet finnes <Link href='https://fabel.no/lydbok/iskalde-gross-9-spokelsesjakt/' target='_blank'>her</Link>.<p></p>
                            <Typography
                                component="h1"
                                variant="h6"
                                align="center"
                                color="text.secondary"
                                gutterBottom
                            >
                                2020
                            </Typography>
                            2020 har vært et spesielt år for alle, inkludert oss hvor vi har hatt begrenset med muligheter til å reise rundt. Vi har alikevell fått gjennomført en del prosjekter. Blant annet har vi hatt et bra samarbeid med Fredriksten Festning. Det er blant annet skrevet en artikkel om det <Link href='https://kandusi.no/en-kveld-som-spokelsesjeger-pa-fredriksten/' target='_blank'>her</Link>. Vi var også med i en episode av Norge Rundt fra festningen. Denne ble vist på NRK 30.10.2020 og er tilgjengelig på <Link href='https://tv.nrk.no/serie/norge-rundt/2020/DVNR04004420/avspiller' target='_blank'>NRK nett TV</Link>.
                        </Typography>
                        <Grid item xs={12}>
                            <Stack direction="row" alignItems="center" gap={1} mt={5} mb={3} className="HeaderTitle">
                                <Typography sx={{ flex: 1 }} className='TitleText'>Oppdrag</Typography>
                            </Stack>
                        </Grid>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            Alle team som samarbeider med organisasjonen tar imot forespørsler om undersøkelser på alle type steder, men det er en vurderingsprosess de går igjennom før en forespørsel kan aksepteres. Dette innebærer at vi blant annet vurderer hendelsene som er beskrevet, og det som også betyr noe her er blant annet hvor mange som har opplevd ting, hvor ofte noe skjer, og hvor nylig siste hendelse var. Det er også andre faktorer som spiller inn på om det er gjennomførbart, som for eksempel økonomi og reisevei. Fordelen med at vi er en større organisasjon er at dersom et team ikke har muligheten til å påta seg oppdraget så kan det være aktuelt for et annet team å gjennomføre, selv om det for eksempel innebærer at det er et team som har lengre reisevei.
                            <p></p>
                            I tillegg til henvendelser teamene mottar om oppdrag så kontakter de også selv steder som de finner interessante, blant annet større lokasjoner som egner seg når det skal lages videoproduksjoner.
                        </Typography>
                        <Grid item xs={12}>
                            <Stack direction="row" alignItems="center" gap={1} mt={5} mb={3} className="HeaderTitle">
                                <Typography sx={{ flex: 1 }} className='TitleText'>Teknisk utstyr</Typography>
                            </Stack>
                        </Grid>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            For å oppnå formålet vårt bruker vi veldig mye teknisk utstyr under våre undersøkelser. Det viktigste utstyret vil alltid være kamera og lydopptaksutstyr av høy kvalitet, men vi bruker også mange forskjellige type sensorer som hjelper til med å dokumentere de hendelsene som skjer på en bedre måte. Du kan lese mer detaljer om utstyret vi bruker <Link href="/utstyr">her</Link>.
                        </Typography>
                    </Grid>
                </Grid>
                <StickyFooter />
            </ThemeProvider>
        </Box >
    );
}

export default Organization;
