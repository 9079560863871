import React, { useEffect, useState } from 'react';
import '../App.css';
import { AppBar, Box, Button, Card, CardActions, CardContent, CardMedia, CircularProgress, createTheme, CssBaseline, Grid, Link, Stack, ThemeProvider, Typography } from '@mui/material';
import StickyFooter from '../components/Footer';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import Header from '../components/Header';
import api from '../api';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function VideoArchive() {
  useEffect(() => {
    document.title = 'Videoer - Norwegian Ghost Hunters';
  });
  const [isloadingyt, setIsLoadingYT] = useState(true);
  const [yt, setYT] = useState([]);
  useEffect(() => {
    api.get("/pub/youtube/all")
      .then((response: any) => {
        setIsLoadingYT(false);
        const yt = response.data;
        setYT(yt);
      })
      .catch((e: Error) => {
      });
  }, []);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar position="sticky" color="transparent">
          <Header />
        </AppBar>
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                href="/"
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Forsiden
              </Link>
              <Link
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="text.primary"
                href="#"
              >
                Videoarkiv
              </Link>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              component="h1"
              variant="h5"
              align="center"
              color="text.secondary"
              gutterBottom
            >
              Videoproduksjoner
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" gap={1} mt={2} className="HeaderTitle">
              <Typography sx={{ flex: 1 }} className='TitleText'>Liste over videoer</Typography>
            </Stack>
          </Grid>
        </Grid>
        {!isloadingyt ?
          <Grid container>
            {
              yt && yt.map(({ youtubeid, teamname, ytvytid, ytvdescription, ytvname, ytvdate }: any) => {
                return <Grid item key={youtubeid} xs={12} sm={12} md={3} lg={3} p={2}>
                  <Card>
                    <Link
                      underline="none"
                      variant="body2"
                      href={"https://www.youtube.com/watch?v=" + ytvytid}
                      target="_blank"
                    >
                      <CardMedia
                        component="img"
                        image={"https://img.youtube.com/vi/" + ytvytid.replace('RJf5X4jJHxY','mE2NfUTV8mw') + "/hq720.jpg"}
                        alt="random"
                      />
                    </Link>
                    <CardContent sx={{ minHeight: 250 }}>
                      <Typography gutterBottom variant="h6" component="h2">
                        {ytvname}
                      </Typography>
                      <Typography color="text.secondary" gutterBottom>
                        {teamname} - {ytvdate}
                      </Typography>
                      <Typography>
                        {ytvdescription}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Link
                        underline="none"
                        variant="body2"
                        href={"https://www.youtube.com/watch?v=" + ytvytid}
                        target="_blank"
                      >
                        <Button size="small" variant="outlined" startIcon={<SmartDisplayIcon />}>Se video</Button>
                      </Link>
                    </CardActions>
                  </Card>
                </Grid>
                  ;
              })
            }
          </Grid>
          : <CircularProgress color="inherit" />}
        <StickyFooter />
      </ThemeProvider>
    </Box >
  );
}

export default VideoArchive;
