import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Home from './pages/Home'
import Organization from './pages/Organization'
import Contact from './pages/Contact'
import TeamDemand from './pages/TeamDemand'
import FAQ from './pages/FAQ'
import Equipment from './pages/Equipment'
import Media from './pages/Media'
import Team from './pages/Team'
import Members from './pages/Members'
import CaseArchive from './pages/CaseArchive'
import VideoArchive from './pages/VideoArchive'
import TeamDetails from './pages/TeamDetails'
import CaseDetails from './pages/CaseDetails'
import ReactGA from 'react-ga4';
import ContactCase from './pages/ContactCase'
import Discord from './pages/Discord'
import OrganizationTeam from './pages/OrganizationTeam'
import ContactTeam from './pages/ContactTeam'
import TeamMember from './pages/TeamMember'
import ContactMember from './pages/ContactMember'
import UserID from './pages/UserID'

ReactGA.initialize("G-49K13GY6ZQ");

const App = () => {
  ReactGA.send("pageview");
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/organisasjon" element={<Organization />} />
          <Route path="/organisasjonteam" element={<OrganizationTeam />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/kontaktoppdrag" element={<ContactCase />} />          
          <Route path="/kontaktteam" element={<ContactTeam />} />
          <Route path="/kontaktmedlem" element={<ContactMember />} />
          <Route path="/utstyr" element={<Equipment />} />
          <Route path="/media" element={<Media />} />
          <Route path="/discord" element={<Discord />} />
          <Route path="/team" element={<Team />} />
          <Route path="/teammedlem" element={<TeamMember />} />
          <Route path="/team/:teamshortname" element={<TeamDetails />} />
          <Route path="/id/:idcard" element={<UserID />} />
          <Route path="/oppdrag" element={<CaseArchive />} />
          <Route path="/oppdrag/:eventpublicurl" element={<CaseDetails />} />
          <Route path="/video" element={<VideoArchive />} />
          <Route path="/medlemmer" element={<Members />} />
          <Route path="/oss" element={<FAQ />} />
          <Route path="/teamregler" element={<TeamDemand />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}
export default App