import React, { useEffect, useState } from 'react';
import '../App.css';
import { AppBar, Box, Card, CardMedia, CircularProgress, createTheme, CssBaseline, Grid, Link, Stack, ThemeProvider, Typography } from '@mui/material';
import StickyFooter from '../components/Footer';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import Header from '../components/Header';
import api from '../api';

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function Team() {
    useEffect(() => {
        document.title = 'Team - Norwegian Ghost Hunters';
    });
    const [isloadingteams, setIsLoadingTeams] = useState(true);
    const [teams, setTeams] = useState([]);
    useEffect(() => {
        api.get("/pub/teams")
            .then((response: any) => {
                setIsLoadingTeams(false);
                const teams = response.data;
                setTeams(teams);
            })
            .catch((e: Error) => {
            });
    }, []);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppBar position="sticky" color="transparent">
                    <Header />
                </AppBar>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Forsiden
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                                href="#"
                            >
                                Team
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                            color="text.secondary"
                            gutterBottom
                        >
                            Norwegian Ghost Hunters team
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12} p={2}>
                        <Stack direction="row" alignItems="center" gap={1} mt={2} mb={3} className="HeaderTitle">
                            <Typography sx={{ flex: 1 }} className='TitleText'>Liste over team</Typography>
                        </Stack>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            {!isloadingteams ?
                                <Grid container>
                                    {
                                        teams && teams.map(({ teamid, teamname, events, members, teambirth, teamshortname, teamngh }: any) => {
                                            if (teamngh === 1) {
                                                var nghteam = "Ja";
                                            }
                                            else {
                                                nghteam = "Nei";
                                            }
                                            return <Grid item xs={12}>
                                                <Link href={"/team/" + teamshortname} underline="none">
                                                    <Grid item key={teamid} xs={12} p={1}>
                                                        <Card variant="elevation" className="CardLink">
                                                            <Card>
                                                                <Grid container>
                                                                    <Grid item xs={12} md={4} lg={3} xl={2} p={1}>
                                                                        <CardMedia
                                                                            style={{
                                                                                width: "auto",
                                                                                maxHeight: "250px"
                                                                            }}
                                                                            component="img"
                                                                            image={"/images/" + teamid + ".png"}
                                                                            alt="random"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} md={8} lg={9} xl={10} pl={2} pt={1} pb={1} pr={1}>
                                                                        <Typography gutterBottom variant="h6" component="h2" color="text.secondary">
                                                                            {teamname} ({teamshortname.toUpperCase()})
                                                                        </Typography>
                                                                        <Typography gutterBottom color="text.secondary">
                                                                            Oppstartsdato: <strong>{teambirth}</strong><br />
                                                                            Utførte oppdrag: <strong>{events}</strong><br />
                                                                            Medlemmer: <strong>{members}</strong><br />
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Card>
                                                        </Card>
                                                    </Grid>
                                                </Link>
                                            </Grid>
                                        })
                                    }
                                </Grid>
                                : <CircularProgress color="inherit" />}
                        </Typography>
                    </Grid>
                </Grid>
                <StickyFooter />
            </ThemeProvider>
        </Box>
    );
}
export default Team;