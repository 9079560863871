import React, { useEffect } from 'react';
import '../App.css';
import { AppBar, Box, createTheme, CssBaseline, Grid, Link, Stack, ThemeProvider, Typography } from '@mui/material';
import StickyFooter from '../components/Footer';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import Header from '../components/Header';

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function TeamMember() {
    useEffect(() => {
        document.title = 'Medlemsskap - Norwegian Ghost Hunters';
    });
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppBar position="sticky" color="transparent">
                    <Header />
                </AppBar>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Forsiden
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                                href="#"
                            >
                                Medlemsskap
                            </Link>
                        </Breadcrumbs>
                    </Grid></Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                            color="text.secondary"
                            gutterBottom
                        >
                            Medlemsskap
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" gap={1} mt={2} mb={3} className="HeaderTitle">
                            <Typography sx={{ flex: 1 }} className='TitleText'>Hva innebærer og kreves det for å være med i et team?</Typography>
                        </Stack>
                        <Typography
                            color="text.secondary"
                            gutterBottom
                        >
                            Å være en del av et team, enten det er et offisielt team under Norwegian Ghost Hunters, eller om det er et samarbeidsteam, involverer en del krav til deg som person for at du skal være til nytte for teamet, og for at teamet skal fungere.
                            <ul>
                                <li><b>Nok tid</b><br />
                                    Dette er en tidkrevende hobby som da krever at du har en livssituasjon hvor du kan prioritere å bruke en del tid på teamet. Team pleier å gjennomføre de fleste oppdrag i helger, så dersom du for eksempel har en jobb hvor du jobber mange helger så kan det bli vanskelig å kombinere. Det er også en stor fordel at man har mulighet til å ta fridager fra jobb innimellom, da det også legges ut på lengre reiser hvor man trenger flere dager enn kun helgen for å få gjennomført oppdraget. Hvis man har flere andre hobbyer som opptar mye tid så kan det også være vanskelig å kombinere.
                                </li>
                                <br />
                                <li><b>Økonomi</b><br />
                                    Alle team drives på frivillig basis, noe som innebærer at alle kostnader dekkes av den enkelte. Du må derfor ha god nok økonomi til blant annet en del reisevirksomhet. Når oppdrag planlegges så samarbeider man i teamet om å finne de gunstigste løsningene med tanke på samkjøring og eventuelt billige overnattingsmuligheter. Som medlem av et team stilles det som regel ikke krav om at du må delta på alt som blir planlagt, men det er en fordel om økonomien ikke er den faktoren som setter begrensningene, og at du har mulighet til å delta på det meste.
                                </li>
                                <br />
                                <li><b>Være aktiv</b><br />
                                    Medlemmer av alle team i Norwegian Ghost Hunters samarbeidet har tilgang på en egen <Link href='/discord'>discord</Link> server, hvor det er en del åpne kanaler hvor alle har tilgang, men også private kanaler kun for team og medlemmer. Enkelte team har ofte også egne servere. Her er det en fordel å være ganske aktiv for å få med seg oppdateringer på hva som skjer i det daglige, samt at det også styrker samholdet når man har mye kontakt med resten av teamet, selv om det er digitalt. Man har også mulighet til å diskutere med folk utenfor team.
                                </li>
                                <br />
                                <li><b>Bosted</b><br />
                                    Bosted er viktig for at et team skal ha muligheten til å være mest mulig aktivt. Noen av teamene er regionale, hvor det kreves at medlemmer bor i nær omkrets av resten av teamet. Men hvis du har mye fritid og er villig til å reise mye, så er det egentlig ikke noe stort problem at du bor lengre unna.
                                </li>
                                <br />
                                <li><b>Førerkort og bil</b><br />
                                    Teamene har individuelle krav til dette punktet, men det er en stor fordel å ha førerkort og bil. Vi forflytter oss på tidspunkter hvor det er veldig begrenset med muligheter til alternativ transport, pluss at vi også befinner oss på områder hvor det ikke er noen andre alternativer enn å kjøre selv. Vi samkjører mest mulig, men desto flere som har førerkort og bil, desto bedre er det så ikke de samme må kjøre hver gang.
                                </li>
                                <br />
                                <li><b>PC og internett</b><br />
                                    En stor og viktig del av det vi gjør er å gå igjennom og analysere video og lyd-opptak i ettertid av gjennomføringen av undersøkelser. Dette er vesentlig for å kunne presentere funn for oppdragsgivere, og eventuell publisering. Det varierer hva slags utstyr team bruker til opptak, men en del av utstyret krever at du har en kraftig nok PC og rask nok internettlinje. Norwegian Ghost Hunters har et felles system hvor alt av opptak kan lastes opp, slik at medlemmer av team kan laste ned og logge hva de finner i filene. På den måten kan man sitte i rolige omgivelser hjemme og konsentrere seg om opptakene, men samtidig har man mulighet til å diskutere funn o.l. med andre i teamet via Discord.
                                </li>
                                <br />
                                <li><b>Er du klarsynt/medium?</b><br />
                                    Hvis du mener du har evner utover det normale så er ikke det noe vi aktivt ønsker å benytte oss av i praksis. Vi er ute etter å undersøke og dokumentere hendelser som alle og enhver kan oppfatte i form av å se eller høre. Så vi legger ingen vekt på hva man føler/mener, da dette havner utenfor vårt område. Du må gjerne føle på ting men det vil ikke påvirke resultatene av undersøkelsene eller bli brukt ved fremlegging av funn, da vi kun fremlegger det vi har dokumentert på lyd eller video.
                                </li>
                                <br />
                                <li><b>Jeg er skeptiker!</b><br />
                                    Det er bare en positiv egenskap som vi ikke stiller oss negativ til, men det er også en forskjell på å være skeptiker og det å være nektende. Innenfor det feltet her så er det mange åpne spørsmål og man må være både nysgjerrig og ha interessen for å både oppleve og dokumentere ting. Hvis man er helt nektende for alt, så har man nok ikke særlig interesse for å bli en del av teamet.
                                </li>
                                <br />
                                <li><b>Mentalitet</b><br />
                                    Du må være sterk mentalt og ikke la deg lett påvirke av for eksempel frykt. Når vi undersøker steder så oppsøker vi fenomener, vi skal ikke løpe vekk fra de. Det er viktig å opptre profesjonelt spesielt ovenfor oppdragsgivere i private boliger.
                                </li>
                                <br />
                                <li><b>Sosialt</b><br />
                                    Vi skal fungere som et bra team sammen, så det er viktig at du sosialt passer godt inn i teamet. Hvis du er lett omgjengelig og sosial utadvendt så er det gode egenskaper. En del team har også arrangement, stiller opp for journalister og lignende, da er det en fordel å ha egenskapene til å takle de situasjonene på en god måte.
                                </li>
                            </ul>
                            <p></p>
                            <Stack direction="row" alignItems="center" gap={1} mt={2} mb={3} className="HeaderTitle">
                                <Typography sx={{ flex: 1 }} className='TitleText'>Hvordan søker jeg medlemsskap?</Typography>
                            </Stack>
                            Hvis du mener at du oppfyller kriteriene ovenfor, så kan du gå til <Link href='/kontaktmedlem'>søk medlemsskap</Link> siden.

                        </Typography>

                    </Grid>
                </Grid>
                <StickyFooter />
            </ThemeProvider>
        </Box>
    );
}

export default TeamMember;
