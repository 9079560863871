import Box from '@mui/material/Box';
import { Button, MenuItem, Toolbar, Link as LinkExt } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import { useState } from 'react';

const NavStyle = {
  textDecoration: "none",
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function Header() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box>
      <Toolbar className="Header">
        <Box display={{ md: "none", sm: "12" }} sx={{ flex: 1 }}>
          <Button
            id="btncust"
            size="large"
            color="inherit"
            aria-controls={open ? 'mobilemenu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            disableElevation
            onClick={handleClick}
            startIcon={<MenuIcon />}
          >
          </Button>
          <StyledMenu
            id="mobilemenu"
            MenuListProps={{
              'aria-labelledby': 'btncust',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <Link to="/" style={NavStyle}>
              <MenuItem onClick={handleClose} disableRipple>
                <Button><HomeIcon /> Forsiden</Button>
              </MenuItem>
            </Link>
            <Link to="/organisasjon" style={NavStyle}>
              <MenuItem onClick={handleClose} disableRipple>
                <Button>Informasjon</Button>
              </MenuItem>
            </Link>
            <Link to="/team" style={NavStyle}>
              <MenuItem onClick={handleClose} disableRipple>
                <Button>Team</Button>
              </MenuItem>
            </Link>
            <Link to="/video" style={NavStyle}>
              <MenuItem onClick={handleClose} disableRipple>
                <Button>Videoer</Button>
              </MenuItem>
            </Link>
            <Link to="/oppdrag" style={NavStyle}>
              <MenuItem onClick={handleClose} disableRipple>
                <Button>Oppdrag</Button>
              </MenuItem>
            </Link>
            <LinkExt href="https://spokelseskartet.no" style={NavStyle} target='_blank'>
              <MenuItem onClick={handleClose} disableRipple>
                <Button>Spøkelseskartet</Button>
              </MenuItem>
            </LinkExt>
            <Link to="/kontakt" style={NavStyle}>
              <MenuItem onClick={handleClose} disableRipple>
                <Button>Kontakt</Button>
              </MenuItem>
            </Link>
          </StyledMenu>
        </Box>
        <Box display={{ xs: "none", md: "block" }} style={{ flex: 1 }}>
          <Link to="/" style={NavStyle}><Button><HomeIcon /></Button></Link>
          <Link to="/organisasjon" style={NavStyle}><Button>Informasjon</Button></Link>
          <Link to="/team" style={NavStyle}><Button>Team</Button></Link>
          <Link to="/video" style={NavStyle}><Button>Videoer</Button></Link>
          <Link to="/oppdrag" style={NavStyle}><Button>Oppdrag</Button></Link>
          <LinkExt href="https://spokelseskartet.no" style={NavStyle} target='_blank'><Button>Spøkelseskartet</Button></LinkExt>
          <Link to="/kontakt" style={NavStyle}><Button>Kontakt</Button></Link>
        </Box>
        <LinkExt
          sx={{ paddingRight: 1 }}
          href="https://www.facebook.com/ghosthuntersno"
          target="_blank"
        >
          <img src='/images/facebook.png' height='38' alt='Facebook' />
        </LinkExt>
        &nbsp;
        <LinkExt
          sx={{ paddingRight: 1 }}
          href="https://instagr.am/norghost"
          target="_blank"
        >
          <img src='/images/instagram.png' height='32' alt='Instagram' />
        </LinkExt>
        &nbsp;
        <LinkExt
          sx={{ paddingRight: 1 }}
          href="/discord"
        >
          <img src='/images/discord.png' height='32' alt='Discord' />
        </LinkExt>
      </Toolbar>
    </Box>
  );
}