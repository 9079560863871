import React, { useEffect, useState } from 'react';
import '../App.css';
import { AppBar, Box, createTheme, CssBaseline, Dialog, Grid, IconButton, Link, Slide, Stack, ThemeProvider, Toolbar, Typography } from '@mui/material';
import StickyFooter from '../components/Footer';
import Header from '../components/Header';
import { CardContent } from '@mui/material';
import { CardActions } from '@mui/material';
import { Button } from '@mui/material';
import { Card } from '@mui/material';
import api from '../api';
import CircularProgress from '@mui/material/CircularProgress';
import { CardMedia } from '@mui/material';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { cyan } from '@mui/material/colors';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import GroupsIcon from '@mui/icons-material/Groups';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import CelebrationIcon from '@mui/icons-material/Celebration';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import TagIcon from '@mui/icons-material/Tag';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { SpectrumVisualizer, SpectrumVisualizerTheme } from 'react-audio-visualizers';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress color="success" variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function Home() {
  useEffect(() => {
    document.title = 'Norwegian Ghost Hunters';
  });
  const [isloadingcases, setIsLoadingCases] = useState(true);
  const [isloadingnextcases, setIsLoadingNextCases] = useState(true);
  const [isloadingyt, setIsLoadingYT] = useState(true);
  const [cases, setCases] = useState([]);
  const [lastclips, setLastClips] = useState([]);
  const [nextcases, setNextCases] = useState([]);
  const [yt, setYT] = useState([]);
  const [cliphash, setClipHash] = useState('');
  const [clipeventname, setClipEventName] = useState('');
  const [clipeventhash, setClipEventHash] = useState('');
  const [clipfile, setClipFile] = useState('');
  const [clipeqname, setClipEqname] = useState('');
  const [clipisvideo, setClipIsVideo] = useState(true);
  const [open, setOpen] = React.useState(false);
  
  function handleClickOpen(chash: any, ehash: any, eventname: any, trname: any, effilename: any, effiletypeid: any) {
    setClipEventHash(ehash);
    setClipEventName(eventname);
    setClipHash(chash);
    setClipFile(effilename);
    setClipEqname(trname);
    if (effiletypeid === 2) {
      setClipIsVideo(false);
    }
    else {
      setClipIsVideo(true);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  useEffect(() => {
    api.get("/pub/events/last")
      .then((response: any) => {
        setIsLoadingCases(false);
        const cases = response.data;
        setCases(cases);
      })
      .catch((e: Error) => {
      });
    api.get("/pub/clips/last")
      .then((response: any) => {
        console.log(response.data);
        setLastClips(response.data);
      })
      .catch((e: Error) => {
      });
  }, []);
  useEffect(() => {
    api.get("/pub/events/next")
      .then((response: any) => {
        setIsLoadingNextCases(false);
        const nextcases = response.data;
        setNextCases(nextcases);
      })
      .catch((e: Error) => {
      });
  }, []);
  useEffect(() => {
    api.get("/pub/youtube/last")
      .then((response: any) => {
        setIsLoadingYT(false);
        const yt = response.data;
        setYT(yt);
      })
      .catch((e: Error) => {
      });
  }, []);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar position="sticky" color="transparent">
          <Header />
        </AppBar>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <Typography sx={{ flex: 1 }}>{clipeventname}</Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          {!isloadingcases ?
            clipisvideo ?
              <video width="100%" height="100%" preload='auto' controls>
                <source src={"https://media.ghosthunters.no/" + clipeventhash + "/" + cliphash + ".mp4"} type="video/mp4" />
              </video>
              :
              <SpectrumVisualizer
                audio={"https://media.ghosthunters.no/" + clipeventhash + "/" + cliphash + ".wav"}
                theme={SpectrumVisualizerTheme.squaredBars}
                colors={['#607D8B', '#607D8B']}
                iconsColor="#777"
                backgroundColor="black"
                showMainActionIcon
                showLoaderIcon
                highFrequency={8000}
              />
            : null
          }
        </Dialog>
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={12} md={8}>
                <Grid container>
                  <Grid item xs={0} md={2}>
                    <img src='logo.png' className='img-fluid' alt='Logo'></img>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Typography
                      component="h1"
                      variant="h5"
                      align="center"
                      color="text.primary"
                      gutterBottom
                    >
                      Norwegian Ghost Hunters
                    </Typography>
                    <p></p>
                    <Typography
                      color="text.secondary"
                      align="center"
                      paragraph
                    >
                      Norwegian Ghost Hunters er en felles organisasjon for grupper som utfører undersøkelser av paranormale fenomener i Norge.<p></p>
                      Formålet med alle våre undersøkelser er å forsøke å få en bedre forståelse for det som i dag ofte omtales som uforklarlige og ukjente fenomener, samt dokumentere disse hendelsene på best mulig måte.
                      Denne nettsiden er en felles portal for alle grupper som samarbeider med organisasjonen, og vil kontinuerlig bli oppdatert med informasjon og opptak fra undersøkelser hvor vi har fått tillatelse til publisering.
                      <p></p>
                      <Link
                        underline="none"
                        variant="body2"
                        href="/organisasjon"
                      >
                        <Button size="small" variant="outlined" startIcon={<ReadMoreIcon />}>Les mer</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
                <Stack direction="row" alignItems="center" gap={1} className="HeaderTitle">
                  <ApartmentIcon />
                  <Typography sx={{ flex: 1 }} className='TitleText'>Siste oppdrag</Typography>
                  <Link href="/oppdrag"><MoreVertIcon className='HeaderIcon' /></Link>
                </Stack>
                {!isloadingcases ?
                  <Grid container>
                    {
                      cases && cases.map(({ eventid, eventhash, eventpublicinfo, eventpublicurl, eventname, eventdate, eventownerteam, eventstatus, eventfileslen, effilesnewlen }: any) => {
                        if (effilesnewlen == null) {
                          var eventperc = 100;
                        }
                        else {
                          var evdone = eventfileslen - effilesnewlen;
                          eventperc = evdone / eventfileslen * 100;
                        }
                        return <Link
                          className='CardCaseLink'
                          href={"/oppdrag/" + eventpublicurl}
                          sx={{ mb: 2 }}
                        >
                          <Grid item key={eventid} xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container>
                              <Card style={{ minWidth: '100%' }}>
                                <Grid container>
                                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <CardMedia
                                      component="img"
                                      image={"https://poimg01.paraoffice.com/event/" + eventhash + ".png"}
                                      alt="random"
                                      // sx={{ height: 270, width: 480 }}
                                      style={{ objectFit: 'cover' }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                    <CardContent>
                                      <Typography variant="h6" component="h2" noWrap>
                                        {eventname}
                                      </Typography>
                                      <div className="TextDetails">
                                        {eventdate} - {eventstatus}
                                      </div>
                                      <span className="TeamBox">{eventownerteam}</span>
                                      <p></p>
                                      <Box sx={{ width: '100%' }}>
                                        <LinearProgressWithLabel value={eventperc} />
                                      </Box>
                                      <p></p>
                                      <Typography sx={{ overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: "3", WebkitBoxOrient: "vertical" }}>
                                        {eventpublicinfo}
                                      </Typography>
                                    </CardContent>
                                  </Grid>
                                </Grid>
                              </Card>
                            </Grid>
                          </Grid>
                        </Link>
                          ;
                      })
                    }
                  </Grid>
                  : <CircularProgress color="inherit" />}
              </Grid>
              <Grid item xs={12} sm={12} md={4} pl={2}>
                {/* <Grid item xs={0} md={1}>
                  </Grid> */}
                <Stack direction="row" alignItems="center" gap={1} className="HeaderTitle">
                  <CalendarMonthIcon />
                  <Typography className='TitleText'>Kalender</Typography>
                </Stack>
                {!isloadingnextcases ?
                  <Grid container>
                    {
                      nextcases && nextcases.map(({ eventid, eventcalendarname, eventdate, eventtime, eventownerteam, eventtypeid }: any) => {
                        if (eventcalendarname == null) {
                          eventcalendarname = '(privat)';
                        }
                        let eventdisp = "0";
                        if (eventtypeid === 1) {
                          eventdisp = "1";
                          return <Grid item xs={12} md={12} key={eventid} pt={2}><Stack direction="row" alignItems="center" gap={1}><BusinessCenterIcon sx={{ color: cyan[800] }} /><Typography color="text.secondary">{eventcalendarname}</Typography></Stack><Typography><span className='TextDetails'>{eventdate} {eventtime} (oppdrag)</span></Typography><span className="TeamBox">{eventownerteam}</span></Grid>;
                        }
                        if (eventtypeid === 2) {
                          eventdisp = "1";
                          return <Grid item xs={12} md={12} key={eventid} pt={2}><Stack direction="row" alignItems="center" gap={1}><TravelExploreIcon sx={{ color: cyan[800] }} /><Typography color="text.secondary">{eventcalendarname}</Typography></Stack><Typography><span className='TextDetails'>{eventdate} {eventtime} (befaring)</span></Typography><span className="TeamBox">{eventownerteam}</span></Grid>;
                        }
                        if (eventtypeid === 3) {
                          eventdisp = "1";
                          return <Grid item xs={12} md={12} key={eventid} pt={2}><Stack direction="row" alignItems="center" gap={1}><CoPresentIcon sx={{ color: cyan[800] }} /><Typography color="text.secondary">{eventcalendarname}</Typography></Stack><Typography><span className='TextDetails'>{eventdate} {eventtime} (fremvisning)</span></Typography><span className="TeamBox">{eventownerteam}</span></Grid>;
                        }
                        if (eventtypeid === 4) {
                          eventdisp = "1";
                          return <Grid item xs={12} md={12} key={eventid} pt={2}><Stack direction="row" alignItems="center" gap={1}><LocalActivityIcon sx={{ color: cyan[800] }} /><Typography color="text.secondary">{eventcalendarname}</Typography></Stack><Typography><span className='TextDetails'>{eventdate} {eventtime} (arrangement)</span></Typography><span className="TeamBox">{eventownerteam}</span></Grid>;
                        }
                        if (eventtypeid === 5) {
                          eventdisp = "1";
                          return <Grid item xs={12} md={12} key={eventid} pt={2}><Stack direction="row" alignItems="center" gap={1}><CelebrationIcon sx={{ color: cyan[800] }} /><Typography color="text.secondary">{eventcalendarname}</Typography></Stack><Typography><span className='TextDetails'>{eventdate} {eventtime} (sosialt)</span></Typography><span className="TeamBox">{eventownerteam}</span></Grid>;
                        }
                        if (eventtypeid === 6) {
                          eventdisp = "1";
                          return <Grid item xs={12} md={12} key={eventid} pt={2}><Stack direction="row" alignItems="center" gap={1}><GroupsIcon sx={{ color: cyan[800] }} /><Typography color="text.secondary">{eventcalendarname}</Typography></Stack><Typography><span className='TextDetails'>{eventdate} {eventtime} (møte)</span></Typography><span className="TeamBox">{eventownerteam}</span></Grid>;
                        }
                        if (eventdisp === "0") {
                          return <Grid item xs={12} md={12} key={eventid} pt={2}><Stack direction="row" alignItems="center" gap={1}><QuestionMarkIcon sx={{ color: cyan[800] }} /><Typography color="text.secondary">{eventcalendarname}</Typography></Stack><Typography><span className='TextDetails'>{eventdate} {eventtime} (møte)</span></Typography><span className="TeamBox">{eventownerteam}</span></Grid>;
                        }
                        return null;
                      })
                    }
                  </Grid>
                  : <CircularProgress color="inherit" />}
                <Stack direction="row" alignItems="center" gap={1} mt={3} className="HeaderTitle">
                  <SearchIcon />
                  <Typography className='TitleText'>Siste publiserte opptak</Typography>
                </Stack>
                {!isloadingcases ?
                  lastclips && lastclips.map(({ eflid, efldescriptionpublic, eflhash, eflhashpub, tags, areas, trname, effilename, eventhash, effiletypeid, eventnamepublic, efltsmoddisp }: any) => {
                    return <Grid item xs={12} p={1}>
                      <Card key={eflid} variant="elevation" className="CardLink" onClick={() => handleClickOpen(eflhash, eventhash, eventnamepublic, trname, effilename, effiletypeid)}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Card>
                              <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                  <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="subtitle2">
                                      {eventnamepublic}
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary" component="div">
                                      publisert {efltsmoddisp}
                                    </Typography>
                                  </CardContent>
                                  <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                    <Typography component="div" variant="caption">
                                      {efldescriptionpublic}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box sx={{ maxWidth: 150 }}>
                                  {effiletypeid === 2 ?
                                    <CardMedia
                                      component="img"
                                      image="../images/audio.png"
                                    />
                                    :
                                    <CardMedia
                                      component="img"
                                      image={"https://media.ghosthunters.no/" + eventhash + "/" + eflhash + ".mp4.jpg"}
                                    />
                                  }
                                </Box>
                              </Grid>
                            </Card>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  })
                  : <CircularProgress color="inherit" />
                }
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" gap={1} className="HeaderTitle">
              <YouTubeIcon />
              <Typography sx={{ flex: 1 }} className='TitleText'>Videoer</Typography>
              <Link href="/video"><MoreVertIcon className='HeaderIcon' /></Link>
            </Stack>
          </Grid>
          {!isloadingyt ?
            <Grid container>
              {
                yt && yt.map(({ youtubeid, teamname, ytvytid, ytvdescription, ytvname, ytvdate }: any) => {
                  return <Grid item key={youtubeid} xs={12} sm={12} md={4} lg={4} p={2}>
                    <Card>
                      <Link
                        underline="none"
                        variant="body2"
                        href={"https://www.youtube.com/watch?v=" + ytvytid}
                        target="_blank"
                      >
                        <CardMedia
                          component="img"
                          image={"https://img.youtube.com/vi/" + ytvytid + "/hq720.jpg"}
                          alt="random"
                        />
                      </Link>
                      <CardContent sx={{ minHeight: 250, maxHeight: 250 }}>
                        <Typography gutterBottom variant="h6" component="h2" noWrap>
                          {ytvname}
                        </Typography>
                        <Typography color="text.secondary" gutterBottom>
                          {teamname} - {ytvdate}
                        </Typography>
                        <Typography sx={{ overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: "5", WebkitBoxOrient: "vertical" }}>
                          {ytvdescription}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Link
                          underline="none"
                          variant="body2"
                          href={"https://www.youtube.com/watch?v=" + ytvytid}
                          target="_blank"
                        >
                          <Button size="small" variant="outlined" startIcon={<SmartDisplayIcon />}>Se video</Button>
                        </Link>
                      </CardActions>
                    </Card>
                  </Grid>
                    ;
                })
              }
            </Grid>
            : <CircularProgress color="inherit" />}
        </Grid>
        <StickyFooter />
      </ThemeProvider >
    </Box >
  );
}
export default Home;