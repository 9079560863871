import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export default function StickyFooter() {
    return (
        <Box
            component="footer"
            sx={{
                py: 3,
                px: 2,
                mt: 'auto',
                color: '#BDBDBD',
                backgroundColor: '#000000'
            }}
        >
            <Container maxWidth="xs">
                <Typography
                    variant="body2"
                >
                    (c) {new Date().getFullYear()} Norwegian Ghost Hunters (926369172)
                </Typography>
            </Container>
            <img src="https://matomo.ghosthunters.no/matomo.php?idsite=1&amp;rec=1" alt="" />
        </Box>
    );
}