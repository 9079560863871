import React, { useEffect } from 'react';
import '../App.css';
import { AppBar, Box, createTheme, CssBaseline, Grid, Link, Stack, ThemeProvider, Typography } from '@mui/material';
import StickyFooter from '../components/Footer';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import Header from '../components/Header';

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function Discord() {
    useEffect(() => {
        document.title = 'Discord - Norwegian Ghost Hunters';
    });
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppBar position="sticky" color="transparent">
                    <Header />
                </AppBar>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Forsiden
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                                href="#"
                            >
                                Discord
                            </Link>
                        </Breadcrumbs>
                    </Grid></Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                            color="text.secondary"
                            gutterBottom
                        >
                            Discord
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" gap={1} mt={2} mb={3} className="HeaderTitle">
                            <Typography sx={{ flex: 1 }} className='TitleText'>Informasjon om Discord</Typography>
                        </Stack>
                        <Typography
                            color="text.secondary"
                            gutterBottom
                        >
                            Vi har en egen Discord server som er et kommunikasjonsverktøy som tilbyr støtte for både chat, video, og lyd samt deling av bilder. Discord er gratis å bruke, og kan brukes både i nettleser, som et eget program på PC, eller som en app på mobiltelefonen.<p></p>
                            På vår server har du mulighet for å komme i kontakt med både oss og andre i miljøet. Her er det muligheter for å dele erfaringer, stille spørsmål, dele egne opptak du har gjort for å få tilbakemeldinger osv.<p></p>
                            <p></p>
                            For å bli med i vår server, <Link href='https://discord.gg/Pw492NvtnW'>klikk her</Link>.
                        </Typography>

                    </Grid>
                </Grid>
                <StickyFooter />
            </ThemeProvider>
        </Box>
    );
}

export default Discord;
