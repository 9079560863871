import React, { useEffect } from 'react';
import '../App.css';
import { AppBar, Box, Chip, createTheme, CssBaseline, Grid, Link, Stack, ThemeProvider, Typography } from '@mui/material';
import StickyFooter from '../components/Footer';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import Header from '../components/Header';

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function TeamDemand() {
    useEffect(() => {
        document.title = 'Rutiner og regler - Norwegian Ghost Hunters';
    });
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppBar position="sticky" color="transparent">
                    <Header />
                </AppBar>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Forsiden
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                                href="#"
                            >
                                Rutiner og regler
                            </Link>
                        </Breadcrumbs>
                    </Grid></Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                            color="text.secondary"
                            gutterBottom
                        >
                            Rutiner og regler for team
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" gap={1} mt={2} mb={3} className="HeaderTitle">
                            <Typography sx={{ flex: 1 }} className='TitleText'>§1 Generelle krav</Typography>
                        </Stack>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            <Chip label="§1.1" size="small" variant="outlined" className='ParaGraph' /> Team som er medlem av Norwegian Ghost Hunters organisasjonen må ha vært aktivt i minimum ett år.<p></p>
                            <Chip label="§1.2" size="small" variant="outlined" className='ParaGraph' /> Det kreves dokumentasjon på minimum 5 gjennomførte oppdrag med tilgjengelige referanser.<br />
                            <Typography className='ParaExtra'>- Videre må man være et aktivt team hvor det kreves at det fortløpende gjennomføres minimum 5 oppdrag per år.</Typography><p></p>
                            <Chip label="§1.3" size="small" variant="outlined" className='ParaGraph' /> Team og medlemmer må ikke ha pådratt seg negativ oppmerksomhet.<br />
                            <Typography className='ParaExtra'>- Det her omfatter ikke vanlig kritiske artikler, men for eksempel omtale om at man har oppsøkt steder ulovlig, hærverk, o.l.</Typography>
                            <Typography className='ParaExtra'>- Medlemmer av teamet skal heller ikke ha negativ oppmerksomhet rundt seg i andre sammenhenger, som kan stille teamet og organisasjonen i et dårlig lys.</Typography><p></p>
                            <Chip label="§1.4" size="small" variant="outlined" className='ParaGraph' /> Alle medlemmer av team må være minimum 18 år.<br />
                            <Typography className='ParaExtra'>- Kravet gjelder fordi personer må ha mulighet til å inngå juridisk bindende avtaler, samt være ansvarlig for seg selv.</Typography>
                            <Typography className='ParaExtra'>- Unntak fra denne regelen kan gjøres dersom majoriteten av teamet er myndige, og det er utnevnt ansvarlig(e) verge(r).</Typography>
                            <Typography className='ParaExtra'>- Regelen gjelder kun for faste medlemmer, og for eksempel ikke for deltakere på arrangement.</Typography><p></p>
                            <Chip label="§1.5" size="small" variant="outlined" className='ParaGraph' /> Team må ha egne signerte kontrakter med sine medlemmer.<br />
                            <Typography className='ParaExtra'>- Kontrakten må inneholde punkter om blant annet ansvar, taushetsplikt, at man godtar opptak av video/lyd, samt rettigheter til å publisere dette offentlig.</Typography>
                            <Typography className='ParaExtra'>- Norwegian Ghost Hunters har en mal tilgjengelig som kan benyttes og tilpasses etter eget behov.</Typography><p></p>
                            <Chip label="§1.6" size="small" variant="outlined" className='ParaGraph' /> Team skal aldri oppsøke lokasjoner som ikke er offentlig tilgjengelig uten tillatelse.<br />
                            <Typography className='ParaExtra'>- Dette gjelder spesielt med tanke på forlatte steder.</Typography><p></p>
                            <Chip label="§1.7" size="small" variant="outlined" className='ParaGraph' /> Andre team skal respekteres og man skal ikke ødelegge for andre.<br />
                            <Typography className='ParaExtra'>- Man ikke oppsøke eller forstyrre andres oppdrag på noen måte.</Typography>
                            <Typography className='ParaExtra'>- Det skal ikke gås ut offentlig å kritisere andre team, men det er rom for generelle uttalelser for å få frem forskjeller på metodikk, utstyr, o.l.</Typography><p></p>
                            <Chip label="§1.8" size="small" variant="outlined" className='ParaGraph' /> Teamet må ha en myndig leder som er enkelt tilgjengelig og deltar på faste felles digitale møter.<br />
                            <Typography className='ParaExtra'>- Organisasjonen har et møte hvert kvartal hvor det forventes at leder av teamet stiller, eller en utnevnt erstatter.</Typography>
                        </Typography>
                        <Stack direction="row" alignItems="center" gap={1} mt={5} mb={3} className="HeaderTitle">
                            <Typography sx={{ flex: 1 }} className='TitleText'>§2 Gjennomføring av oppdrag</Typography>
                        </Stack>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            <Chip label="§2.1" size="small" variant="outlined" className='ParaGraph' /> Det kreves et minimum av teknisk utstyr for å kunne gjennomføre og dokumentere oppdrag.<br />
                            <Typography className='ParaExtra'>- Minimum ett kamera med nattmodus med ekstra IR lys og FullHD eller bedre oppløsning.</Typography>
                            <Typography className='ParaExtra'>- Minimum en lydopptaker (diktafon) med høykvalites lyd.</Typography>
                            <Typography className='ParaExtra'>- Tekniske måleapparater som måler eksempelvis EMF, statisk, bevegelse og temperatur.</Typography><p></p>
                            <Chip label="§2.2" size="small" variant="outlined" className='ParaGraph' /> Det skal aldri under noen omstendigheter skapes falske hendelser eller opptak.<br />
                            <Typography className='ParaExtra'>- Forsøk på forfalskninger av hendelser for å skape mer oppmerksomhet og spenning i eksempelvis videoproduksjoner er uakseptabelt.</Typography>
                            <Typography className='ParaExtra'>- Dersom man er involvert i andres produksjoner (f.eks. for TV eller YouTube) så bør man sikre seg på best mulig måte i kontrakt, men redaktøransvaret er ofte hos produsent uten mulighet for påvirkning av klipping etc. Dersom en produksjon fra en tredjepart fremstiller teamet i dårligs lys, og det er snakk om et gjentakende samarbeid bør man trekke seg fra videre produksjoner.</Typography><p></p>
                            <Chip label="§2.3" size="small" variant="outlined" className='ParaGraph' /> Klarsynte/medium benyttes ikke under normale oppdrag.<br />
                            <Typography className='ParaExtra'>- Det er rom for å eksperimentere med klarsynte/medium, men det bør unngås på vanlige oppdrag, da vår rolle er å dokumentere hendelser på en måte som alle kan oppfatte (video/lyd).</Typography><p></p>
                            <Chip label="§2.4" size="small" variant="outlined" className='ParaGraph' /> Team skal ikke tilby "rensing av hus".<br />
                            <Typography className='ParaExtra'>- Formålet med "ghost hunting" er å forsøke å dokumentere at det eksisterer paranormale fenomener. Før det er allment akseptert at bevisene er gode nok, og at det er mulig å gjøre noe med det, så vil det være feil å gå ut å påstå at man har evnen til å fjerne noe som ikke en gang er bevist at eksisterer.</Typography>
                            <Typography className='ParaExtra'>- Dersom man er i en situasjon at oppdragsgiver/eier av lokasjon ønsker denne tjenesten så kan man henvise til noen som driver med det, dersom man understreker at det ikke har noe med eget team å gjøre og at det ikke finnes noen garantier på at det vil fungere eller ha noen som helst verdi. Oppfordres til å si at det ikke anbefales.</Typography><p></p>
                            <Chip label="§2.5" size="small" variant="outlined" className='ParaGraph' /> Team skal utføre alle oppdrag gratis<br />
                            <Typography className='ParaExtra'>- Vi er en frivillig organisasjon som skal være tilgjengelig for alle, uavhengig av økonomi. For medlemmer av team anses dette som en hobby, og ikke jobb. Det forventes derfor at medlemmer har god nok økonomi til å bistå teamet nok.</Typography>
                            <Typography className='ParaExtra'>- Dersom et team mottar et oppdrag de ikke har mulighet til å gjennnomføre pga manglende økonomi, så oppfordres det til å høre om andre team i organisasjonen har muligheten til å ta det.</Typography>
                            <Typography className='ParaExtra'>- Hvis oppdragsgiver på eget intiativ tilbyr seg å betale for at det skal være mulig å gjennomføre, så oppfordres det til å takke nei og overlate oppdraget til et annet team som kan gjennomføre gratis. Men i særtilfeller aksepteres det, dersom det ikke skjer ofte.</Typography>
                            <Typography className='ParaExtra'>- Frivillige donasjoner fra publikum er akseptert, det er også rom for registrering av frivillig organisasjon og motta grasrotandel fra Norsk Tipping.</Typography><p></p>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <p></p>
                    </Grid>
                </Grid>
                <StickyFooter />
            </ThemeProvider>
        </Box>
    );
}

export default TeamDemand;
