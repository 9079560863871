import React, { FC, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { Button, Card, CardContent, Grid, Link, MenuItem, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import SendIcon from '@mui/icons-material/Send';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import api from '../api';


interface IFormInputs {
    contactteam: string;
    contactname: string;
    contactemail: string;
    contactcell: number;
    contactcity: string;
    contactbirthday: string;
    contacttitle: string;
    contactfacebook: string;
    contactinstagram: string;
    archdescription: string;
}

const schema = yup.object().shape({
    contactname: yup.string().min(5).required(),
    contactemail: yup.string().email().required(),
    contactcell: yup.number().moreThan(40000000).lessThan(99999999).required(),
    contactcity: yup.string().min(1).required(),
    contactbirthday: yup.string().required(),
    contacttitle: yup.string().min(1).required(),
    contactfacebook: yup.string().min(2),
    contactinstagram: yup.string().min(2),
    archdescription: yup.string().min(10).required(),
});

const ContactSchemeMember: FC = () => {
    const [viewscheme, setViewScheme] = useState(true);
    const [caseref, setCaseRef] = useState(null);
    const [isloadingteams, setIsLoadingTeams] = useState(true);
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        api.get("/pub/teams")
            .then((response: any) => {
                setIsLoadingTeams(false);
                const teams = response.data;
                setTeams(teams);
            })
            .catch((e: Error) => {
            });
    }, []);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInputs>({
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<IFormInputs> = (data) => {
        setOpen(!open);
        axios({
            method: "post",
            url: "https://api2.paraoffice.com:8080/api/pub/arch/contactmember",
            data: data,
            headers: { "Content-Type": "application/json" },
        })
            .then(response => {
                setOpen(false);
                setViewScheme(false);
                setCaseRef(response.data[0].archref);
            });
    }

    const [open, setOpen] = React.useState(false);

    return (
        viewscheme ?

            <Grid item xs={12} spacing={2} p={2}>
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
                <Stack direction="row" alignItems="center" gap={1} mt={2} mb={3} className="HeaderTitle">
                    <Typography sx={{ flex: 1 }} className='TitleText'>Søknadsskjema</Typography>
                </Stack>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography
                                        color="text.secondary"
                                    >
                                        Før innsending av dette skjemaet er det viktig at du har lest igjennom <Link href="/teammedlem">denne</Link> siden som inneholder informasjon og hva som kreves for å være en del av et team.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" alignItems="center" gap={1} mt={2} mb={2} className="HeaderTitle">
                                <Typography sx={{ flex: 1 }} className='TitleText'>Velg team</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} mb={2}>
                            <Card>
                                <CardContent>
                                    <Typography
                                        color="text.secondary"
                                    >
                                        Hvis du vil søke inn til et konkret team så velg teamet i listen. Hvis du vil sende inn en generell søknad som flere team kan vurdere så velg "Norwegian Ghost Hunters".
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Controller
                                name="contactteam"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        select
                                        fullWidth
                                        label="Mottaker team"
                                        value={value}
                                        defaultValue="1"
                                        onChange={onChange}
                                    >
                                        <MenuItem key='1' value='1'>
                                            Norwegian Ghost Hunters
                                        </MenuItem>
                                        {teams.map((option: any) => (
                                            <MenuItem key={option.teamid} value={option.teamid}>
                                                {option.teamname}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />
                        </Grid>
                        <Grid xs={12} pl={1}>
                            <Stack direction="row" alignItems="center" mt={3} mb={1} className="HeaderTitle">
                                <Typography sx={{ flex: 1 }} className='TitleText'>Generelle opplysninger</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Controller
                                name="contactname"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Fullt Navn"
                                        variant="outlined"
                                        error={!!errors.contactname}
                                        helperText={errors.contactname ? errors.contactname?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Controller
                                name="contactemail"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="E-post"
                                        variant="outlined"
                                        error={!!errors.contactemail}
                                        helperText={errors.contactemail ? errors.contactemail?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Controller
                                name="contactcell"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Mobiltelefon"
                                        variant="outlined"
                                        error={!!errors.contactcell}
                                        helperText={errors.contactcell ? errors.contactcell?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Controller
                                name="contactcity"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Bosted"
                                        variant="outlined"
                                        error={!!errors.contactcity}
                                        helperText={errors.contactcity ? errors.contactcity?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Controller
                                name="contactbirthday"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        type="date"
                                        label="Fødselsdato"
                                        variant="outlined"
                                        defaultValue="2000-01-01"
                                        error={!!errors.contactbirthday}
                                        helperText={errors.contactbirthday ? errors.contactbirthday?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Controller
                                name="contacttitle"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Yrke/Arbeidssituasjon"
                                        variant="outlined"
                                        error={!!errors.contacttitle}
                                        helperText={errors.contacttitle ? errors.contacttitle?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>

                    </Grid>
                    <Grid xs={12} mb={2}>
                        <Stack direction="row" alignItems="center" mt={3} mb={1} className="HeaderTitle">
                            <Typography sx={{ flex: 1 }} className='TitleText'>Sosiale medier</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography
                                    color="text.secondary"
                                >
                                    Grunnen til at vi ber om sosiale medier kontoer er for at vi skal kunne få et inntrykk av hvem du er som person. Feltene er ikke påkrevd.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <p></p>
                    <Grid container spacing={2} pb={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Controller
                                name="contactfacebook"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Facebook brukernavn"
                                        variant="outlined"
                                        error={!!errors.contactfacebook}
                                        helperText={errors.contactfacebook ? errors.contactfacebook?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Controller
                                name="contactinstagram"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Instagram brukernavn"
                                        variant="outlined"
                                        error={!!errors.contactinstagram}
                                        helperText={errors.contactinstagram ? errors.contactinstagram?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} pb={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="archdescription"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Søknad med informasjon"
                                        placeholder="Skriv en utfyllende tekst med informasjon om deg selv og hvorfor du ønsker å bli en del av et team."
                                        variant="outlined"
                                        multiline
                                        rows="10"
                                        error={!!errors.archdescription}
                                        helperText={errors.archdescription ? errors.archdescription?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Button variant="outlined" type='submit' startIcon={<SendIcon />}>Send inn</Button>
                </form>
            </Grid>
            :
            <Typography
                component="h1"
                variant="h5"
                align="center"
                color="text.primary"
                gutterBottom
            >
                Takk for din søknad, vi besvarer så raskt som mulig (referanse: {caseref}).
            </Typography>
    );
};

export default ContactSchemeMember;