import React, { FC, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { Alert, Button, Card, CardContent, Checkbox, FormControlLabel, Grid, Link, MenuItem, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import SendIcon from '@mui/icons-material/Send';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import api from '../api';

interface IFormInputs {
    contactteam: string;
    contactname: string;
    contactemail: string;
    contactcell: number;
    contactbirthday: string;
    archtitle: string;
    archdescription: string;
    teamdate: string;
    teamcases: string;
    caselocation1: string;
    caselocation2: string;
    caselocation3: string;
    caselocation4: string;
    caselocation5: string;
    teammembers: string;
    teamequipment: string;
}

const schema = yup.object().shape({
    contactname: yup.string().min(5).required(),
    contactcell: yup.number().moreThan(40000000).lessThan(99999999).required(),
    contactemail: yup.string().email().required(),
    contactbirthday: yup.string().required(),
    teamdate: yup.string().required(),
    teamcases: yup.number().moreThan(4).required(),
    caselocation1: yup.string().required(),
    caselocation2: yup.string().required(),
    caselocation3: yup.string().required(),
    caselocation4: yup.string().required(),
    caselocation5: yup.string().required(),
    teammembers: yup.string().required(),
    teamequipment: yup.string().min(10).required(),
    archtitle: yup.string().min(4).required(),
    archdescription: yup.string().min(10).required(),
});

const ContactSchemeTeam: FC = () => {
    const [viewscheme, setViewScheme] = useState(true);
    const [caseref, setCaseRef] = useState(null);

    useEffect(() => {
    }, []);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInputs>({
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<IFormInputs> = (data) => {
        console.log("subber!");
        setOpen(!open);
        axios({
            method: "post",
            url: "https://api2.paraoffice.com:8080/api/pub/arch/contactteam",
            data: data,
            headers: { "Content-Type": "application/json" },
        })
            .then(response => {
                setOpen(false);
                setViewScheme(false);
                setCaseRef(response.data[0].archref);
            });
    }

    const [open, setOpen] = React.useState(false);

    return (
        viewscheme ?

            <Grid item xs={12} spacing={2} p={2}>
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
                <Stack direction="row" alignItems="center" gap={1} mt={2} mb={3} className="HeaderTitle">
                    <Typography sx={{ flex: 1 }} className='TitleText'>Søknadsskjema</Typography>
                </Stack>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography
                                        color="text.secondary"
                                    >
                                        Før innsending av dette skjemaet er det viktig at teamet ditt oppfyller alle krav som stilles på <Link href="/teamregler">rutiner og regler</Link> siden.<p></p>
                                        Skjemaet skal kun fylles ut av leder av teamet som ønsker samarbeid. Dersom søknaden blir avslått vil teamet det søkes for måtte vente ett år før ny søknad kan sendes.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid xs={12} pl={1}>
                            <Stack direction="row" alignItems="center" mt={3} mb={1} className="HeaderTitle">
                                <Typography sx={{ flex: 1 }} className='TitleText'>Leder av teamet</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Controller
                                name="contactname"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Fullt Navn"
                                        variant="outlined"
                                        error={!!errors.contactname}
                                        helperText={errors.contactname ? errors.contactname?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Controller
                                name="contactemail"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="E-post"
                                        variant="outlined"
                                        error={!!errors.contactemail}
                                        helperText={errors.contactemail ? errors.contactemail?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Controller
                                name="contactcell"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Mobiltelefon"
                                        variant="outlined"
                                        error={!!errors.contactcell}
                                        helperText={errors.contactcell ? errors.contactcell?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Controller
                                name="contactbirthday"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        type="date"
                                        label="Fødselsdato"
                                        variant="outlined"
                                        defaultValue="2000-01-01"
                                        error={!!errors.contactbirthday}
                                        helperText={errors.contactbirthday ? errors.contactbirthday?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography
                            color="text.secondary"
                        >
                            <FormControlLabel
                                label="Jeg bekrefter at jeg er leder av teamet"
                                control={
                                    <Checkbox />
                                }
                            />
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Stack direction="row" alignItems="center" mt={3} mb={1} className="HeaderTitle">
                            <Typography sx={{ flex: 1 }} className='TitleText'>Teamet</Typography>
                        </Stack>
                    </Grid>
                    <Grid container spacing={2} pb={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Controller
                                name="archtitle"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Navn på teamet"
                                        variant="outlined"
                                        error={!!errors.archtitle}
                                        helperText={errors.archtitle ? errors.archtitle?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Controller
                                name="teamdate"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        type="date"
                                        label="Oppstartsdato"
                                        variant="outlined"
                                        defaultValue="2022-01-01"
                                        error={!!errors.teamdate}
                                        helperText={errors.teamdate ? errors.teamdate?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Controller
                                name="teamcases"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        type="number"
                                        label="Antall utførte oppdrag totalt"
                                        variant="outlined"
                                        error={!!errors.teamcases}
                                        helperText={errors.teamcases ? errors.teamcases?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography
                                        color="text.secondary"
                                    >
                                        Oppgi fem lokasjoner hvor gruppen har gjennomført oppdrag. Private bolighus o.l. kan oppgis med bolighus + stedsnavn, f.eks: "Bolighus Oslo".
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                            <Controller
                                name="caselocation1"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Lokasjon #1"
                                        variant="outlined"
                                        error={!!errors.caselocation1}
                                        helperText={errors.caselocation1 ? errors.caselocation1?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                            <Controller
                                name="caselocation2"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Lokasjon #2"
                                        variant="outlined"
                                        error={!!errors.caselocation2}
                                        helperText={errors.caselocation2 ? errors.caselocation2?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                            <Controller
                                name="caselocation3"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Lokasjon #3"
                                        variant="outlined"
                                        error={!!errors.caselocation3}
                                        helperText={errors.caselocation3 ? errors.caselocation3?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                            <Controller
                                name="caselocation4"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Lokasjon #4"
                                        variant="outlined"
                                        error={!!errors.caselocation4}
                                        helperText={errors.caselocation4 ? errors.caselocation4?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                            <Controller
                                name="caselocation5"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Lokasjon #5"
                                        variant="outlined"
                                        error={!!errors.caselocation5}
                                        helperText={errors.caselocation5 ? errors.caselocation5?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography
                                    color="text.secondary"
                                >
                                    List opp alle medlemmer av teamet i boksen under med følgende opplysninger:
                                    <ul>
                                        <li>Fullt navn</li>
                                        <li>E-post</li>
                                        <li>Fødselsdato</li>
                                        <li>Dato for når de ble medlem</li>
                                    </ul>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid container spacing={2} pb={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="teammembers"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Medlemmer"
                                        variant="outlined"
                                        multiline
                                        rows="10"
                                        error={!!errors.teammembers}
                                        helperText={errors.teammembers ? errors.teammembers?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid><p></p>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography
                                    color="text.secondary"
                                >
                                    List opp utstyr som teamet besitter å bruker under oppdrag:
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid container spacing={2} pb={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="teamequipment"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Utstyr"
                                        variant="outlined"
                                        multiline
                                        rows="10"
                                        error={!!errors.teamequipment}
                                        helperText={errors.teamequipment ? errors.teamequipment?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid><p></p>                    
                    <Stack direction="row" alignItems="center" gap={1} mt={2} mb={3} className="HeaderTitle">
                    <Typography sx={{ flex: 1 }} className='TitleText'>Søknad</Typography>
                </Stack>
                <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography
                                    color="text.secondary"
                                >
                                    Oppgi alle andre relevante opplysninger om teamet, og hvorfor dere ønsker samarbeid. Link til nettside og sosiale medier.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid container spacing={2} pb={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="archdescription"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Søknad med informasjon"
                                        variant="outlined"
                                        multiline
                                        rows="10"
                                        error={!!errors.archdescription}
                                        helperText={errors.archdescription ? errors.archdescription?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography
                            color="text.secondary"
                        >
                            <FormControlLabel
                                label="Jeg bekrefter at teamet oppfyller alle krav som stilles"
                                control={
                                    <Checkbox />
                                }
                            />
                        </Typography>
                    </Grid>
                    <Button variant="outlined" type='submit' startIcon={<SendIcon />}>Send inn</Button>
                </form>
            </Grid>
            :
            <Typography
                component="h1"
                variant="h5"
                align="center"
                color="text.primary"
                gutterBottom
            >
                Takk for din søknad, vi besvarer så raskt som mulig (referanse: {caseref}).
            </Typography>
    );
};

export default ContactSchemeTeam;