import React, { FC, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { Alert, Button, Grid, Link, MenuItem, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import SendIcon from '@mui/icons-material/Send';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import api from '../api';

interface IFormInputs {
    contactteam: string;
    contactname: string;
    contactemail: string;
    locationname: string;
    locationaddress: string;
    locationzipcode: string;
    locationcity: string;
    contactcell: number;
    archdescription: string;
}

const schema = yup.object().shape({
    contactname: yup.string().min(5).required(),
    contactcell: yup.number().moreThan(40000000).lessThan(99999999),
    contactemail: yup.string().email(),
    locationaddress: yup.string().min(5).required(),
    locationzipcode: yup.number().moreThan(1).lessThan(9999),
    locationcity: yup.string().min(1).required(),
    locationname: yup.string().min(5).required(),
    archdescription: yup.string().min(10).required(),
});

const ContactSchemeCase: FC = () => {
    const [viewscheme, setViewScheme] = useState(true);
    const [caseref, setCaseRef] = useState(null);
    const [isloadingteams, setIsLoadingTeams] = useState(true);
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        api.get("/pub/teams")
            .then((response: any) => {
                setIsLoadingTeams(false);
                const teams = response.data;
                setTeams(teams);
            })
            .catch((e: Error) => {
            });
    }, []);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInputs>({
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<IFormInputs> = (data) => {
        setOpen(!open);
        axios({
            method: "post",
            url: "https://api2.paraoffice.com:8080/api/pub/arch/contactcase",
            data: data,
            headers: { "Content-Type": "application/json" },
        })
            .then(response => {
                setOpen(false);
                setViewScheme(false);
                setCaseRef(response.data[0].archref);
            });
    }

    const [open, setOpen] = React.useState(false);

    return (
        viewscheme ?

            <Grid item xs={12} spacing={2} p={2}>
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
                <Stack direction="row" alignItems="center" gap={1} mt={2} mb={3} className="HeaderTitle">
                    <Typography sx={{ flex: 1 }} className='TitleText'>Kontaktskjema for oppdragsforespørsel</Typography>
                </Stack>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} pb={2}>
                        <Grid item xs={12}>
                            <Alert severity="info">
                                <Typography color="text.secondary" gutterBottom>
                                    Dette skjemaet er for å sende oss en forespørsel om gjennomføring av en undersøkelse. Skjemaet skal kun brukes dersom du er grunneier, eller representerer grunneier med en fullmakt hvor det gis tilgang til en av våre grupper for å gjennomføre en undersøkelse. Dersom du kun er ute etter å tipse oss om en lokasjon som du ikke har noe eierskap til, benytt det vanlige <Link href="/nyttoppdrag">kontaktskjemaet</Link>.
                                </Typography>
                            </Alert>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Controller
                                name="contactteam"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        select
                                        fullWidth
                                        label="Mottaker team"
                                        value={value}
                                        defaultValue="1"
                                        onChange={onChange}
                                    >
                                        <MenuItem key='1' value='1'>
                                            Norwegian Ghost Hunters
                                        </MenuItem>
                                        {teams.map((option: any) => (
                                            <MenuItem key={option.teamid} value={option.teamid}>
                                                {option.teamname}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />
                            <Alert severity="info">Er du usikker på hvilket team du skal kontakte, bruk "Norwegian Ghost Hunters" så vil vi ta en vurdering innad i organisasjonen.</Alert>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} pb={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Controller
                                name="locationname"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Navn på stedet"
                                        variant="outlined"
                                        error={!!errors.locationname}
                                        helperText={errors.locationname ? errors.locationname?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                            <Alert severity="info">Navn på stedet er for eksempel "Nes Kirkeruiner" o.l. Hvis det er snakk om et privat bolighus, oppgi bolighus og stedsnavn, for eksempel: Bolighus Oslo.</Alert>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} pb={2}>



                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Controller
                                name="contactname"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Fullt Navn"
                                        variant="outlined"
                                        error={!!errors.contactname}
                                        helperText={errors.contactname ? errors.contactname?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Controller
                                name="contactemail"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="E-post"
                                        variant="outlined"
                                        error={!!errors.contactemail}
                                        helperText={errors.contactemail ? errors.contactemail?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Controller
                                name="contactcell"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Mobiltelefon"
                                        variant="outlined"
                                        error={!!errors.contactcell}
                                        helperText={errors.contactcell ? errors.contactcell?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} pb={2}>

                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Controller
                                name="locationaddress"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Addresse"
                                        variant="outlined"
                                        error={!!errors.locationaddress}
                                        helperText={errors.locationaddress ? errors.locationaddress?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Controller
                                name="locationzipcode"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Postnummer"
                                        variant="outlined"
                                        error={!!errors.locationzipcode}
                                        helperText={errors.locationzipcode ? errors.locationzipcode?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Controller
                                name="locationcity"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Sted"
                                        variant="outlined"
                                        error={!!errors.locationcity}
                                        helperText={errors.locationcity ? errors.locationcity?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>


                    </Grid>

                    <Grid container spacing={2} pb={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="archdescription"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Beskriv utfyllende opplevelser og eventuelt historien til stedet"
                                        variant="outlined"
                                        multiline
                                        rows="10"
                                        error={!!errors.archdescription}
                                        helperText={errors.archdescription ? errors.archdescription?.message : ''}
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Button variant="outlined" type='submit' startIcon={<SendIcon />}>Send inn</Button>
                </form>
            </Grid>
            :
            <Typography
                component="h1"
                variant="h5"
                align="center"
                color="text.primary"
                gutterBottom
            >
                Takk for din henvendelse, vi besvarer så raskt som mulig (referanse: {caseref}).
            </Typography>
    );
};

export default ContactSchemeCase;