import React, { useEffect, useState } from 'react';
import '../App.css';
import { AppBar, Box, Card, CardContent, CardMedia, CircularProgress, createTheme, CssBaseline, Grid, Link, Paper, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableRow, Tabs, ThemeProvider, Typography } from '@mui/material';
import StickyFooter from '../components/Footer';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import Header from '../components/Header';
import api from '../api';
import { useParams } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import ImageIcon from '@mui/icons-material/Image';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TeamDetails() {
    const { teamshortname } = useParams();
    useEffect(() => {
        document.title = 'Team ' + teamshortname + ' - Norwegian Ghost Hunters';
    });
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const [isloadingteams, setIsLoadingTeams] = useState(true);
    const [teamname, setTeamName] = useState(null);
    const [teamid, setTeamID] = useState(null);
    const [teammembers, setTeamMembers] = useState([false]);
    const [teams, setTeams] = useState<any[]>([]);
    useEffect(() => {
        api.get("/pub/team/" + teamshortname)
            .then((response: any) => {
                const teams = response.data;
                setTeams(teams);
                setTeamName(teams[0]?.teamname);
                setTeamID(teams[0]?.teamid);
                setTeamMembers(teams[0]?.members);
                setIsLoadingTeams(false);
            })
            .catch((e: Error) => {
            });
    }, [teamshortname]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppBar position="sticky" color="transparent">
                    <Header />
                </AppBar>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Forsiden
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/team"
                            >
                                Team
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                                href="#"
                            >
                                {teamname}
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Typography
                            component="h1"
                            variant="h6"
                            align="center"
                            color="text.secondary"
                            gutterBottom
                        >
                            {teamname}
                        </Typography>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            {!isloadingteams ?
                                <div>
                                    <Grid container>
                                        <Grid item key={teamid} xs={12} p={1}>
                                            <Grid container xs={12}>
                                                <Grid item xs={12} md={12} lg={3} xl={2}>
                                                    <CardMedia
                                                        style={{
                                                            width: "auto",
                                                            maxHeight: "250px"
                                                        }}
                                                        component="img"
                                                        image={"/images/" + teamid + ".png"}
                                                        alt="random"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={12} lg={6} xl={7} p={1}>
                                                    <Card variant="outlined">
                                                        <CardContent>
                                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                                {teams[0]?.teamwebinfo}
                                                            </Typography>
                                                            <hr></hr>
                                                            <Stack direction="row" alignItems="center" gap={2}>
                                                                {teams[0].teamurl ?
                                                                    <Link href={teams[0].teamurl} target='_blank' sx={{ mt: 1 }}><img src='/images/homepage.png' height='42px' alt='Homepage'></img></Link>
                                                                    : null
                                                                }
                                                                {teams[0].teamfacebook ?
                                                                    <Link href={'https://facebook.com/' + teams[0].teamfacebook} target='_blank'><img src='/images/facebook.png' height='39px' alt='Facebook'></img></Link>
                                                                    : null
                                                                }
                                                                {teams[0].teaminstagram ?
                                                                    <Link href={'https://instagram.com/' + teams[0].teaminstagram} target='_blank'><img src='/images/instagram.png' height='35px' alt='Instagram'></img></Link>
                                                                    : null
                                                                }
                                                                {teams[0].teamyoutube ?
                                                                    <Link href={'https://youtube.com/' + teams[0].teamyoutube} target='_blank'><img src='/images/youtube.png' height='40px' alt='YouTube'></img></Link>
                                                                    : null
                                                                }
                                                            </Stack>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                                <Grid item xs={12} md={12} lg={3} xl={3} p={1}>
                                                    <Card>
                                                        <CardContent>
                                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                                <Typography align="center">{teams[0].teamname}</Typography>
                                                                <hr></hr>
                                                                <TableContainer component={Paper}>
                                                                    <Table aria-label="simple table">
                                                                        <TableBody>
                                                                            <TableRow>
                                                                                <TableCell>Etablert</TableCell>
                                                                                <TableCell align="right">{teams[0]?.teambirth}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell>Medlemmer</TableCell>
                                                                                <TableCell align="right">{teams[0]?.memberscount}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell>Oppdrag</TableCell>
                                                                                <TableCell align="right">{teams[0]?.events}</TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} pt={2} >
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <Tabs value={value} onChange={handleChange} aria-label="tabs" variant="fullWidth">
                                                <Tab label="Team medlemmer" {...a11yProps(0)} />
                                                <Tab label="Steder undersøkt" {...a11yProps(1)} />
                                            </Tabs>
                                        </Box>
                                        <TabPanel value={value} index={0}>
                                            <Grid container>
                                                {
                                                    teammembers && teammembers.map(({ userid, userhash, userdispname, age, memberyears, userphone, useremail, userfacebook, userinstagram, usersnapchat, events }: any) => {
                                                        return <Grid item key={teamid} style={{ margin: 5, minWidth: 600 }}>
                                                            <Card
                                                                sx={{ width: 600, height: 250, display: 'flex', flexDirection: 'row' }}
                                                            >
                                                                <CardMedia
                                                                    sx={{ maxHeight: 250, maxWidth: 250 }}
                                                                    component="img"
                                                                    image={"https://poimg01.paraoffice.com/profiles/" + userhash + "_p.jpg"}
                                                                    alt="random"
                                                                />
                                                                <CardContent sx={{ flexGrow: 1 }}>
                                                                    <Typography gutterBottom>
                                                                        {userdispname} ({age})
                                                                    </Typography>
                                                                    <Typography gutterBottom>
                                                                        {memberyears} år medlem<br />
                                                                        {events} oppdrag<br />
                                                                    </Typography>
                                                                    {userphone &&
                                                                        <Typography gutterBottom variant="body2" color="text.secondary" className='texticontypo'>
                                                                            <img src='/images/phoneicon.png' height='24'></img> <Link href={"tel:" + userphone}>{userphone}</Link>
                                                                        </Typography>
                                                                    }
                                                                    {useremail &&
                                                                        <Typography gutterBottom variant="body2" color="text.secondary" className='texticontypo'>
                                                                            <img src='/images/mailicon.png' height='24'></img> &nbsp; <Link href={"mailto:" + useremail}>{useremail}</Link>
                                                                        </Typography>
                                                                    }
                                                                    {userfacebook &&
                                                                        <Typography gutterBottom variant="body2" color="text.secondary" className='texticontypo'>
                                                                            <img src='/images/facebookicon.png' height='24'></img> <Link href={"https://facebook.com/" + userfacebook} target='_blank'>{userfacebook}</Link>
                                                                        </Typography>
                                                                    }
                                                                    {userinstagram &&
                                                                        <Typography gutterBottom variant="body2" color="text.secondary" className='texticontypo'>
                                                                            <img src='/images/instaicon.png' height='24'></img> <Link href={"https://instagram.com/" + userinstagram} target='_blank'>{userinstagram}</Link>
                                                                        </Typography>
                                                                    }
                                                                    {usersnapchat &&
                                                                        <Typography gutterBottom variant="body2" color="text.secondary" className='texticontypo'>
                                                                            <img src='/images/snapicon.png' height='24'></img> {usersnapchat}
                                                                        </Typography>
                                                                    }
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    })
                                                }
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value={value} index={1}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" gutterBottom>Steder som teamet har undersøkt</Typography>
                                                    {
                                                        teams[0]?.locations && teams[0]?.locations.map(({ locationname }: any) => {
                                                            return <Typography>{locationname}</Typography>
                                                        })
                                                    }
                                                </Grid>
                                            </Grid>
                                        </TabPanel>

                                    </Grid>
                                </div>
                                : <CircularProgress color="inherit" />
                            }
                        </Typography>
                    </Grid>
                </Grid>
                <StickyFooter />
            </ThemeProvider>
        </Box>
    );
}
export default TeamDetails;