import React, { useEffect, useState } from 'react';
import '../App.css';
import { AppBar, Box, Card, CardMedia, CircularProgress, createTheme, CssBaseline, Grid, Link, List, Stack, ThemeProvider, Typography } from '@mui/material';
import StickyFooter from '../components/Footer';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import Header from '../components/Header';
import api from '../api';

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function CaseArchive() {
    useEffect(() => {
        document.title = 'Oppdragsarkiv - Norwegian Ghost Hunters';
    });
    const [isloadingcases, setIsLoadingCases] = useState(true);
    const [cases, setCases] = useState([]);
    useEffect(() => {
        api.get("/pub/events/all")
            .then((response: any) => {
                setIsLoadingCases(false);
                const cases = response.data;
                setCases(cases);
            })
            .catch((e: Error) => {
            });
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppBar position="sticky" color="transparent">
                    <Header />
                </AppBar>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Forsiden
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                                href="#"
                            >
                                Oppdragsarkiv
                            </Link>
                        </Breadcrumbs>
                    </Grid></Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                            color="text.secondary"
                            gutterBottom
                        >
                            Oppdragsarkiv
                        </Typography>      
                        </Grid>
                        </Grid>
                        <Grid container spacing={2} p={2}>
                            <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" gap={1} mt={2} mb={2} className="HeaderTitle">
                            <Typography sx={{ flex: 1 }} className='TitleText'>Liste over oppdrag</Typography>
                        </Stack>
                        <Typography
                            color="text.secondary"
                            paragraph
                        >
                            {!isloadingcases ?
                                <Grid container>
                                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                        {
                                            cases && cases.map(({ eventid, eventname, eventdate, eventpublicurl, eventstatus, eventhash, eventpublicinfo, eventownerteam, eventlogs, teams }: any) => {
                                                return <Grid item key={eventid} xs={12} p={1}>
                                                    <Link href={"/oppdrag/" + eventpublicurl} underline="none"><Card variant="elevation" className="CardLink">
                                                        <Grid container>
                                                            <Grid item xs={12} md={4} lg={2} p={1}>
                                                                <CardMedia
                                                                    component="img"
                                                                    image={"https://poimg01.paraoffice.com/event/" + eventhash + ".png"}
                                                                    height="100%"
                                                                    width="100%"
                                                                    alt="random"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={8} lg={10} pl={2} pt={1} pb={1} pr={1}>
                                                                {eventname}
                                                                <div className="TextDetails">{eventdate} - {eventstatus} ({eventlogs} funn)</div><p></p>
                                                                <Typography
                                                                    color="text.secondary"
                                                                    gutterBottom
                                                                >
                                                                    {eventpublicinfo}
                                                                </Typography>
                                                                <div className="TextDetails">
                                                                    <span className="TeamBox">{eventownerteam}</span>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Card></Link>
                                                </Grid>

                                            })
                                        }
                                    </List>
                                </Grid>
                                : <CircularProgress color="inherit" />}

                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <p></p>
                    </Grid>
                </Grid>
                <StickyFooter />
            </ThemeProvider>
        </Box>
    );
}

export default CaseArchive;
